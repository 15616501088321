import React from "react";
import { isIterableArray } from "../../helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "./Flex";
import classNames from "classnames";

const Avatar = ({
	size = "xl",
	rounded = "circle",
	src,
	name,
	firstname,
	lastname,
	emoji = "🙂",
	className,
	mediaClass,
	isExact = false,
	icon,
}) => {
	const classNames = ["avatar", `avatar-${size}`, className].join(" ");
	const mediaClasses = [rounded ? `rounded-${rounded}` : "rounded", mediaClass].join(" ");

	const getAvatar = () => {
		if (src) {
			if (isIterableArray(src)) {
				return (
					<div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
						<div className="w-50 border-end">
							<img
								loading="lazy"
								src={src[0]}
								alt=""
								onError={(e) =>
									(e.target.src = `https://oasmedia.s3.us-east-1.amazonaws.com/pictures/noimage.png`)
								}
							/>
						</div>
						<div className="w-50 d-flex flex-column">
							<img src={src[1]} alt="" className="h-50 border-bottom" />
							<img src={src[2]} alt="" className="h-50" />
						</div>
					</div>
				);
			} else {
				return (
					<img
						loading="lazy"
						className={mediaClasses}
						src={src}
						alt=""
						onError={(e) =>
							(e.target.src = `https://oasmedia.s3.us-east-1.amazonaws.com/pictures/noimage.png`)
						}
					/>
				);
			}
		}

		if (name) {
			return (
				<div className={`bg-primary avatar-name ${mediaClasses}`}>
					<span>{isExact ? name : name?.match(/\b\w/g)?.join("")}</span>
				</div>
			);
		}

		if (firstname && lastname) {
			return (
				<div className={`bg-primary avatar-name ${mediaClasses}`}>
					<span>
						{isExact
							? firstname + lastname
							: firstname?.match(/\b\w/g)?.join("") + lastname?.match(/\b\w/g)?.join("")}
					</span>
				</div>
			);
		}

		if (icon) {
			return (
				<Flex className={`avatar-name ${mediaClasses}`}>
					<FontAwesomeIcon icon={icon} />
				</Flex>
			);
		}

		return (
			<div className={`avatar-emoji ${mediaClasses}`}>
				<span role="img" aria-label="Emoji">
					{emoji}
				</span>
			</div>
		);
	};

	return <div className={classNames}>{getAvatar()}</div>;
};

export const AvatarGroup = ({ children, dense, className }) => {
	return (
		<div
			className={classNames(className, "avatar-group", {
				"avatar-group-dense": dense,
			})}
		>
			{children}
		</div>
	);
};

export default Avatar;
