import { Fragment } from 'react'
import store, { useBehaviorSubject } from '../store'
// import { Fade } from 'react-bootstrap'

const AppMask = () => {
  const [mask] = useBehaviorSubject(store.mask)
  return mask ? (
    <Fragment>
      {/* <Fade in={true} id="mask"> */}
      <div id="mask">
        <div
          className="lds-css"
          style={{
            position: 'fixed',
            top: 'calc(40vh - 70px)',
            left: 'calc(50vw - 70px)'
          }}
        >
          <div className="lds-dual-ring">
            <div></div>
          </div>
        </div>
      </div>
      {/* </Fade> */}
    </Fragment>
  ) : (
    ''
  )
}

export default AppMask
