import React from "react";
import store, { useBehaviorSubject, clearConfirm } from "../store";
import { Button, Modal } from "react-bootstrap";

export default function Confirm() {
	const [confirm] = useBehaviorSubject(store.confirm);

	if (confirm === null) {
		return null;
	}

	var ModalBody;

	if (confirm.description && confirm.description !== "") {
		ModalBody =
			typeof confirm.description === "object" ? (
				<Modal.Body>{confirm.description}</Modal.Body>
			) : (
				<Modal.Body>
					<div dangerouslySetInnerHTML={{ __html: confirm.description }} />
				</Modal.Body>
			);
	}

	const okConfirm = (val) => {
		clearConfirm();
		confirm.cb(val);
	};

	const btnType = confirm.btnType;

	const okVal2 = confirm.okVal2.length ? (
		<Button size="sm" className="ms-2" variant={`outline-${btnType}`} onClick={() => okConfirm(2)}>
			{confirm.okVal2}
		</Button>
	) : (
		""
	);

	const cancelButton = confirm.cancelVal.length ? (
		<Button size="sm" variant="outline-secondary" onClick={clearConfirm}>
			{confirm.cancelVal}
		</Button>
	) : (
		""
	);

	return (
		<Modal show={true} centered={true} backdropClassName="oas-confirm-backdrop">
			{confirm.title && (
				<div className="modal-header">
					<h4 className="modal-title">{confirm.title}</h4>
				</div>
			)}
			{ModalBody}
			<div className="modal-footer">
				<div className="text-end">
					{cancelButton}{" "}
					<Button size="sm" className="ms-2" variant={`outline-${btnType}`} onClick={() => okConfirm(1)}>
						{confirm.okVal}
					</Button>{" "}
					{okVal2}
				</div>
			</div>
		</Modal>
	);
}
