import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconButton = ({
  icon,
  iconAlign = 'left',
  iconClassName,
  transform,
  children,
  spinner = false,
  spinnerVariant = 'primary',
  tooltip = '',
  placement = 'auto',
  style,
  ...rest
}) => {
  const Butt = (
    <Button {...rest} style={{ ...style, borderRadius: 4 }}>
      {iconAlign === 'right' && children}
      {spinner ? (
        <Spinner as="div" size="xs" animation="border" className="me-2 mt-1" variant={spinnerVariant} />
      ) : (
        <FontAwesomeIcon
          icon={icon}
          className={classNames(iconClassName, {
            'me-1': children && iconAlign === 'left',
            'ms-1': children && iconAlign === 'right'
          })}
          transform={transform}
        />
      )}
      {iconAlign === 'left' && children}
    </Button>
  )

  return tooltip.length ? (
    <OverlayTrigger delay={500} overlay={<Tooltip>{tooltip}</Tooltip>} placement={placement}>
      {Butt}
    </OverlayTrigger>
  ) : (
    Butt
  )
}

IconButton.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  children: PropTypes.any,
  iconAlign: PropTypes.oneOf(['left', 'right']),
  iconClassName: PropTypes.string,
  transform: PropTypes.string
}

export default IconButton
