import React from 'react'
import { oasColors } from '/src/helpers/misc'

const SraIcon = ({ type, truthy, onClick = () => {}, title, children, cursor = 'pointer' }) => {
  return (
    <span
      className="circle"
      style={{
        color: truthy ? oasColors[type].color : oasColors.default.color,
        backgroundColor: truthy ? oasColors[type].backgroundColor : oasColors.default.backgroundColor,
        cursor
      }}
      onClick={() => onClick()}
      title={title}
    >
      {children}
    </span>
  )
}

export default SraIcon
