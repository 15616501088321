import React, { Fragment } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Confirm from './helpers/Confirm'
import AppMask from './helpers/AppMask'

import 'react-datepicker/dist/react-datepicker.css'
import Layout from './layouts/Layout'
// import { MsalProvider } from '@azure/msal-react'
// import { PublicClientApplication } from '@azure/msal-browser'
import { isURLKnown } from './helpers/misc'

// CS testing this as the way to load style
import '/src/assets/scss/theme.scss'

// import APIService from "./helpers/APIService";

// const msalConfig2 = {
//   auth: {
//     clientId: import.meta.env.VITE_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID,
//     authority: 'https://login.microsoftonline.com/common',
//     redirectUri: window.location.origin
//   }
// }

// // this is v2 of the @azure/msal-browser package.  MP has V3...or maybe it doesn't
// const msalInstance = new PublicClientApplication(msalConfig2)

const App = () => {
  const ku = isURLKnown
  if (ku) {
    document.querySelectorAll('[rel="icon"]')[0].href = ku.favicon
  }
  return (
    <Fragment>
      <AppMask />
      <Confirm />
      <Router basename={import.meta.env.BASE_URL}>
        <Layout />
      </Router>
    </Fragment>
  )
}

export default App
