import React, { useState, useRef, useContext, useEffect } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import APIService from "../../helpers/APIService";
import { PageNameContext } from "../../components/PageContext";
import store, { useBehaviorSubject } from "../../store";
import { toast } from "react-toastify";
import { toastError } from "../../helpers/misc";

export default function HelpBlob({ children, helpName, overridePageName, color = "#4f6df5", blobBefore = false }) {
	const [helpText, setHelpText] = useState("");
	const [editMode, setEditMode] = useState(false);
	const [session] = useBehaviorSubject(store.session);
	const [container, setContainer] = useState();

	const ref = useRef(null);

	const contextPageName = useContext(PageNameContext) || "noPageContext";
	const pageName = overridePageName || contextPageName;

	if (!helpName) {
		helpName = children;
	}

	if (contextPageName === "noPageContext") console.log("Error: Page without pageContext " + window.location.pathname);

	useEffect(() => {
		setContainer(Array.from(document.querySelectorAll(".modal, .shoommodal")).pop());
	}, []);

	const cleanPageName = pageName.replace(/\s/g, "_").replace("/", "").toLowerCase();

	const cleanHelpName = helpName
		.replace(/\s/g, "_")
		.replace("/", "")
		.replace("(", "")
		.replace(")", "")
		.replace("#", "")
		.replace(":", "")
		.toLowerCase();

	const target = cleanPageName + "-" + cleanHelpName;

	const isSuperHuman = session && parseInt(session?.userid, 10) < 1000;

	const edit = () => {
		if (isSuperHuman) {
			setEditMode(!editMode);
		}
	};

	const save = () => {
		const html = ref.current.value;
		APIService.post("func/saveTip/" + cleanPageName + "/" + cleanHelpName, {
			html,
		}).then((response) => {
			if (!response.exception) {
				setEditMode(false);
				setHelpText(html);
				toast.success(response.message || "Save complete");
			} else {
				toastError(response.exception);
				console.log("response.exception:", response.exception);
			}
		});
	};

	const PopoverBody = editMode ? (
		<div>
			<textarea
				className="form-control"
				style={{ width: 250, fontSize: 12, background: "#FAEBD7" }}
				rows={15}
				ref={ref}
				defaultValue={helpText}
				onBlur={() => {
					save();
				}}
			/>
		</div>
	) : (
		<Popover.Body>
			<div dangerouslySetInnerHTML={{ __html: helpText }} />
		</Popover.Body>
	);

	const overlay = (
		<Popover id="popover-basic">
			<Popover.Header>
				<div style={{ cursor: isSuperHuman ? "pointer" : "" }} onClick={() => edit()}>
					<b>{helpName}</b>
				</div>
			</Popover.Header>
			{PopoverBody}
		</Popover>
	);

	const loadPopover = () => {
		APIService.post("func/loadTip/" + cleanPageName + "/" + cleanHelpName).then((response) => {
			setEditMode(false);
			setHelpText(response.text);
		});
	};

	const blobEnd = blobBefore ? null : (
		<FontAwesomeIcon
			id={target}
			style={{ color, fontSize: 12, cursor: "pointer" }}
			icon={faInfoCircle}
			className="cursor-pointer ms-1"
		/>
	);

	const blobStart = blobBefore ? (
		<FontAwesomeIcon
			id={target}
			style={{ color, fontSize: 2, cursor: "pointer" }}
			icon={faInfoCircle}
			className="cursor-pointer me-1"
		/>
	) : null;

	return (
		<OverlayTrigger
			trigger="click"
			placement="auto"
			rootClose
			overlay={overlay}
			container={container}
			// onExited={() =>{}}
		>
			<span
				onClick={(e) => {
					e.preventDefault();
					loadPopover();
				}}
			>
				{blobStart}
				{children}
				{blobEnd}
			</span>
		</OverlayTrigger>
	);
}
