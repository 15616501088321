import { ErrorBoundary } from 'react-error-boundary'
import { Button } from 'react-bootstrap'
import APIService from '../../helpers/APIService'
import store, { useBehaviorSubject } from '../../store'
import { detect } from 'detect-browser'
import { sendSlackMessage, logout } from '../../helpers/misc'

function ErrorFallback({ error, resetErrorBoundary }) {
  const browser = detect()

  const chunkError = error.message.includes('Loading chunk') || error.message.includes('dynamically imported') // Loading chunk was for cra

  const searchGoogle = () => {
    const url = `https://www.google.com/search?q=clear+browser+cache+${browser?.name}`
    window.open(url, '_blank')
  }

  return chunkError ? (
    <div className="text-center mt-5">
      <div role="alert">
        <img className="pt-2 pb-1" src="https://oasesstore.s3.amazonaws.com/resources/oops.png" alt="Oops" width={80} />
        <h3>Browser Cache Problem</h3>
        <div className="mt-4 mb-3">
          <h5>Please clear your browser cache.</h5>
          <br />
          <br />
          To do this do a Google search for: <br />
          <b>clear browser cache [your browser name]</b> <br />
          e.g. clear browser cache chrome
        </div>
        <Button variant="falcon-default" size="sm" className="ms-2" onClick={resetErrorBoundary}>
          Try Again
        </Button>
        <Button variant="falcon-default" size="sm" className="ms-2" onClick={searchGoogle}>
          Search for Instructions
        </Button>
        <Button variant="falcon-default" size="sm" className="ms-2" onClick={logout}>
          Log out
        </Button>
      </div>
    </div>
  ) : (
    <div className="text-center mt-5">
      <div role="alert">
        <img className="pt-2 pb-1" src="https://oasesstore.s3.amazonaws.com/resources/oops.png" alt="Oops" width={80} />
        <h3>Oops. Something went wrong.</h3>
        <div className="mt-4 mb-3">Please make a note of exactly what you just did, and note this error message:</div>
        <pre
          className="form-control mb-5"
          style={{
            color: 'red',
            width: '75%',
            margin: '0 auto',
            height: 'auto',
            whiteSpace: 'normal'
          }}
        >
          {error.message}
        </pre>
        <Button variant="falcon-default" size="sm" className="ms-2" onClick={resetErrorBoundary}>
          Try Again
        </Button>
        <Button variant="falcon-default" size="sm" className="ms-2" onClick={logout}>
          Log out
        </Button>
      </div>
    </div>
  )
}

const formatSlack = (error, info, session) => {
  return {
    blocks: [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*ErrorBoundary alert. TLA: ${session.comptla} User: ${session.sys_fullname} (${session.userid})*`
        }
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `• url: *${window.location.href}* 
• Message: ${error.message}`
        }
      }
    ]
  }
}

// • Text: ${JSON.stringify(info.componentStack.toString(), null, 2)}`

const myErrorHandler = (error, info, session) => {
  // c onsole.log('info', info)
  // c onsole.log('error', error)
  // Do something with the error
  // there's error.message and error.stack
  // E.g. log to an error logging client here
  // c onsole.log("ERROR from db"+store.session.value.comptla ,error);
  // sendSlackMessage('ERROR from db ' + store.session.value.comptla + '  ==>  '+  error)

  const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.sessionStorage.getItem('refreshed') || 'false')

  const chunkError = error.message.includes('Loading chunk') || error.message.includes('dynamically imported') // Loading chunk was for cra

  if (chunkError && !pageHasAlreadyBeenForceRefreshed) {
    window.sessionStorage.setItem('refreshed', 'true')
    sendSlackMessage(`ErrorBoundary Reload. TLA: ${session.comptla} User: ${session.sys_fullname} (${session.userid})*`, false, 'magenta')
    window.location.reload()
  } else {
    sendSlackMessage(formatSlack(error, info, session), true, 'magenta')
  }

  APIService.post('apim/log_system_event', {
    calledfrom: 'magenta',
    // text: error.message
    text: error.message + ' --> ' + info.componentStack.toString() // good details on localhost, but no sourcemap on magenta
    // text: error.message + ' --> ' + error.toString()  // only first line
    // text: error // empty field
  })
  if (import.meta.env.NODE_ENV !== 'development') {
    // console.clear()
    console.log('***  Please report the next two lines ***\n ', error)
  }
}

const UseErrorBoundary = ({ children }) => {
  const [session] = useBehaviorSubject(store.session)

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, info) => myErrorHandler(error, info, session)}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoundary>
  )
}

export default UseErrorBoundary
