import React, { useState, useEffect, Fragment, forwardRef } from 'react'
import { Link } from 'react-router-dom'
import Avatar from '../components/common/Avatar'
import Flex from '../components/common/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row, Button, Dropdown, Form, FloatingLabel, OverlayTrigger, Tooltip, ButtonGroup, Popover, Spinner } from 'react-bootstrap'
import classNames from 'classnames'
import APIService from '/src/helpers/APIService'
import IconButton from '/src/components/common/IconButton'
import { toast } from 'react-toastify'
import store from '../store'
import { parse, format } from 'date-fns'
import Loader from '../components/common/Loader'
import HelpBlob from '../components/common/HelpBlob'
import images from 'react-payment-inputs/images'
import styled from 'styled-components'
import SoftBadge from '../components/common/SoftBadge'
import createMarkup from '/src/helpers/createMarkup'
import { nanoid } from 'nanoid'
import CryptoJS from 'crypto-js'
import dayjs from 'dayjs'
import SraIcon from './SraIcon'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import customParseFormat from 'dayjs/plugin/customParseFormat' // dependent on utc plugin

import calendar from 'dayjs/plugin/calendar'
import relativeTime from 'dayjs/plugin/relativeTime'
import XLSX from 'xlsx'
import { faFile, faFilePdf, faFileWord, faFileExcel, faFileCsv } from '@fortawesome/free-solid-svg-icons'
import DOMPurify from 'dompurify'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(calendar)
dayjs.extend(relativeTime)

export const toTz = (timestr, fmtFrom = 'YYYY-MM-DD HH:mm:ss') => {
  const dbfmt = store.session.value.dateFormat === 'MM-dd-yy' ? 'MM-DD-YYYY HH:mm:ss' : 'DD-MM-YYYY HH:mm:ss'
  try {
    return dayjs.tz(timestr, dbfmt, 'America/New_York').tz(dayjs.tz.guess()).format(dbfmt)
  } catch (_) {
    try {
      return dayjs.tz(timestr, fmtFrom, 'America/New_York').tz(dayjs.tz.guess()).format(dbfmt)
    } catch (_) {
      console.log('toTz catch error timestr', timestr)
      return timestr
    }
  }
}

export const logout = async () => {
  await APIService.post('logout')
  APIService.token = null
  // cookie.set('removedtoken2 on logout', 'true')
  // cookie.remove('oastoken')
  // cookie.remove('oastoken', { path: '/', domain: '.oasesonline.com', Secure: true })
  document.cookie = 'cookieName=oasmagtoken; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  sessionStorage.removeItem('oasmagtoken')
  deleteStoreKey('grids')
  setTimeout(() => {
    store.session.next(null)
  }, 500)
  // setRedirectUrl
  window.location.href = window.location.origin
}

export const oasColors = {
  default: {
    color: '#fff',
    backgroundColor: '#ccc'
  },
  submit: {
    color: '#344050',
    backgroundColor: '#F7DCB1'
  },
  review: {
    color: '#344050',
    backgroundColor: '#B1E0F7'
  },
  approve: {
    color: '#344050',
    backgroundColor: '#B3F7B1'
  },
  paid: {
    color: '#344050',
    backgroundColor: '#FEC7FD'
  },
  paidIncPrior: {
    color: '#344050',
    backgroundColor: '#6a81ed'
  },
  custom: {
    color: '#344050',
    backgroundColor: '#dbc4fb'
    // backgroundColor: '#acb3fa'
  }
}

export const reloadConfig = async (withUserArrays = false, silent = false) => {
  // const param = withUserArrays ? '/true' : ''
  // const response = await APIService.post(`apim/getAPISession${param}`)
  const response = await APIService.post('login/get_session')
  store.session.next(decryptSession(response.session))
  if (!silent) toast.success('Operation complete')
}

const staffStatus = (status) => {
  let color = ''

  switch (status.toUpperCase()) {
    case 'ACTIVE':
      color = 'success'
      break
    case 'PENDING':
      color = 'primary'
      break
    case 'hold':
      color = 'secondary'
      break
    case 'pending':
      color = 'warning'
      break
    default:
      color = 'warning'
  }

  // icon = 'redo'; icon = 'ban'; icon = 'stream'; icon = 'stream'; icon = 'check';

  return color
}
const studentStatus = (status) => {
  let color = ''

  switch (status?.toUpperCase()) {
    case 'ACTIVE':
      color = 'success'
      break
    case 'INACTIVE':
      color = 'secondary'
      break
    case 'PENDING':
      color = 'primary'
      break
    case 'COMPLETE':
      color = 'info'
      break
    case 'RECEIVED':
      color = 'warning'
      break
    case 'ASSIGNED':
      color = 'info'
      break
    default:
      color = 'warning'
  }
  return color
}

export const ActionButton = ({
  tooltip,
  icon,
  onClick,
  variant = 'light',
  iconClassName = '',
  withGroup = false,
  width = 30,
  transform = '',
  className = 'shadow-none text-500',
  spinner = false
}) => {
  const button = (
    <OverlayTrigger delay={500} overlay={<Tooltip>{tooltip}</Tooltip>} placement="top">
      <Button
        // 2022-02-23: changed to zIndex 1 because of session detail page, session type combo
        style={{ zIndex: 1, width, padding: 5 }}
        variant={variant}
        onClick={onClick}
        className={className}
      >
        {spinner ? (
          <Spinner style={{ marginBottom: 1 }} size="xs" animation="border" variant="success" />
        ) : (
          <FontAwesomeIcon className={iconClassName} icon={icon} transform={transform} />
        )}
      </Button>
    </OverlayTrigger>
  )
  return withGroup ? (
    <ButtonGroup size="sm" className="end-0 me-0 grid-icon-actions">
      {button}
    </ButtonGroup>
  ) : (
    button
  )
}

export const offset = (el) => {
  const rect = el?.getBoundingClientRect()
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return { top: rect?.top + scrollTop, left: rect?.left + scrollLeft }
}

export const isEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b)
}

export const Oasspan = forwardRef(({ children, ...rest }, ref) => (
  <span ref={ref} {...rest}>
    {children}
  </span>
))

export const staffBadgeFormatter = (cell, row, rowIndex, formatExtraData = {}) => {
  let props = formatExtraData
  let color = props.color || staffStatus(cell)
  const maxWidth = (props && props.maxWidth) || 150
  const icon = (props && props.icon) || null

  return (
    <SoftBadge pill bg={`${color}`} className="rounded-capsule fs--1 d-block" style={{ maxWidth }}>
      {cell}
      {icon && <FontAwesomeIcon icon={icon} transform="shrink-2" className="ms-1" />}
    </SoftBadge>
  )
}

export const studentBadgeFormatter = (cell) => {
  const color = studentStatus(cell)
  return staffBadgeFormatter(cell, null, null, { color })
}

export const parentFormatter = (cell, row, rowIndex, formatExtraData) => {
  const popover = (
    <Popover style={{ width: 'auto', maxWidth: 'none' }}>
      <Popover.Header as="h3">
        {row.firstname} {row.lastname}
      </Popover.Header>
      <Popover.Body>{row.parent?.length ? htmlFormatter(row.parent) : 'No data'}</Popover.Body>
    </Popover>
  )

  const link = +row.parid
  const isYes = row.parent?.length
  const color = isYes ? 'success' : 'warning'
  const display = isYes ? 'Y' : 'N'
  return isYes ? (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={popover}>
      {link ? (
        <Link
          to={{
            pathname: `/people/customers/${row.parid}`
          }}
        >
          <SoftBadge bg={color} pill>
            {display}
          </SoftBadge>
        </Link>
      ) : (
        <div>
          <SoftBadge bg="primary" pill>
            {display}
          </SoftBadge>
        </div>
      )}
    </OverlayTrigger>
  ) : (
    <SoftBadge bg={color} pill>
      {display}
    </SoftBadge>
  )
}

export const DetailRow = ({ title, isLastItem, helpBlob, children, ...rest }) => {
  const displayTitle = helpBlob ? (
    <HelpBlob helpName={helpBlob === true ? title : helpBlob}>
      <div
        className={classNames('d-inline-block fw-semi-bold ' + rest.className, {
          'mb-0': isLastItem,
          'mb-1': !isLastItem
        })}
      >
        {title}
      </div>
    </HelpBlob>
  ) : (
    <p
      className={classNames('fw-semi-bold ' + rest.className, {
        'mb-0': isLastItem,
        'mb-1': !isLastItem
      })}
    >
      {title}
    </p>
  )
  return (
    <Row>
      <Col xs={rest.xs || 5} sm={rest.sm || 4}>
        {displayTitle}
      </Col>
      <Col>{children}</Col>
    </Row>
  )
}

export const colMap = {
  sub: 'text-warning',
  rev: 'text-primary',
  app: 'text-success'
}

export const authlevelRenderer = (cell, row, rowIndex, formatExtraData) => {
  switch (cell) {
    case '1':
      return store.session.value.LabAdmin
    case '20':
      return store.session.value.LabRegDir
    case '2':
      return store.session.value.LabCoord
    case '3':
      return store.session.value.LabLeadT
    case '4':
      return store.session.value.LabTutor
    case '5':
      return store.session.value.LabParent
    default:
      return cell
  }
}

export const checkFormatter = (cell, row, rowIndex, formatExtraData = '', spinner = false) => {
  const icon = +cell ? 'check' : 'times'
  const color = +cell ? 'green' : 'red'
  if ((cell === null || parseInt(cell, 10) === 0) && formatExtraData.includes('no-x')) return ''
  if (formatExtraData === 'add-pwset') {
    return (
      <Fragment>
        {spinner ? (
          <Spinner size="xs" animation="border" variant="primary" />
        ) : (
          <FontAwesomeIcon color={color} icon={icon} transform="shrink-2" className="ms-1" />
        )}
        {row.pwset}
      </Fragment>
    )
  }
  return <FontAwesomeIcon color={color} icon={icon} transform="shrink-2" className="ms-1 cursor-pointer" />
}

export const getCardImage = (cardType) => {
  switch (cardType) {
    case 'American Express':
      return images.amex
    case 'Visa':
      return images.visa
    case 'MasterCard':
      return images.mastercard
    case 'Diners Club':
      return images.dinersclub
    case 'Discover':
      return images.discover
    default:
      return images.placeholder
  }
}

export const toastError = (message) => {
  return toast.error(htmlFormatter(message))
}

export const addUnassigned = (arr, labelName = 'label', valueName = 'value') => {
  return [{ [valueName]: -10, id: -10, [labelName]: 'Unassigned', value: -10, label: 'Unassigned' }, ...arr]
}

export const htmlFormatter = (cell) => <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cell) }} />
export const htmlFormatterSpan = (cell) => <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cell) }} />

export const htmlHeaderFormatter = (column) => <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(column.text.replace('\\', '')) }} />
export const htmlHeaderFormatterTS = (cell) => <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cell.replace('\\', '')) }} />

export const yesNoFormatter = (cell, row, rowIndex, formatExtraData) => {
  const isYes = (typeof cell === 'string' && cell.toUpperCase() === 'YES') || parseInt(cell, 10) === 1
  const color = isYes ? 'success' : 'warning'
  const display = isYes ? 'Yes' : 'No'
  return (
    <SoftBadge bg={color} pill>
      {display}
    </SoftBadge>
  )
}

export const get_contrastingcol = (color) => {
  if (color.substring(0, 1) === '#') {
    color = color.substring(1)
  }
  const R = parseInt(color.substring(0, 1), 16)
  const G = parseInt(color.substring(2, 1), 16)
  const B = parseInt(color.substring(4, 1), 16)
  return G > 10 || R + G + B > 30 ? '#000000' : '#FFFFFF'
}

export const BadgeFormatter = ({ children, ...props }) => {
  const color = (props && props.color) || 'success'

  return (
    <SoftBadge bg={color} pill>
      {children}
    </SoftBadge>
  )
}

export const staffNameFormatter = (dataField, { firstname, lastname, avatar, id, status, highlight }, index, formatExtraData) => (
  <Link to={{ pathname: `/people/staff/${id}`, idList: formatExtraData }}>
    <Flex alignItems="center" className={classNames('position-relative', { 'oas-highlight': +highlight })} style={{ borderRadius: 20 }}>
      <Avatar {...avatar} firstname={firstname} lastname={lastname} />
      <Flex className="ms-2">
        <h5 className="mb-0 fs--1 staff-name py-2" title={status}>
          {firstname} {lastname}
        </h5>
      </Flex>
    </Flex>
  </Link>
)

export const custNameFormatter = (dataField, { tid, tutid, fullname }) => {
  const id = tid || tutid
  return (
    <Link to={`/people/customers/${id}#billing`}>
      <h5 name="fullname" className="mb-0 fs--1 link-hover overflow-ellipsis" style={{ maxWidth: 150 }}>
        {fullname}
      </h5>
    </Link>
  )
}

export const customerNameFormatter = (dataField, { firstname, lastname, avatar, tid, status, highlight }, index, formatExtraData) => (
  <Link to={{ pathname: `/people/customers/${tid}`, idList: formatExtraData }}>
    <Flex alignItems="center" className={classNames('position-relative', { 'oas-highlight': +highlight })} style={{ borderRadius: 20 }}>
      <Avatar {...avatar} />
      <Flex className="ms-2">
        <h5 className="mb-0 fs--1 customer-name" title={status}>
          {firstname} {lastname}
        </h5>
      </Flex>
    </Flex>
  </Link>
)
export const customerInvPrivNameFormatter = (dataField, { firstname, lastname, avatar, tid, status, highlight }, index, formatExtraData) => (
  <Link to={{ pathname: `/people/customers/${tid}#billing`, idList: formatExtraData }}>
    <Flex alignItems="center" className={classNames('position-relative', { 'oas-highlight': +highlight })} style={{ borderRadius: 20 }}>
      <Avatar {...avatar} />
      <Flex className="ms-2">
        <h5 className="mb-0 fs--1 customer-name" title={status}>
          {firstname} {lastname}
        </h5>
      </Flex>
    </Flex>
  </Link>
)

export const AvatarFormatted = ({ avatar, firstname, lastname, status = '' }) => (
  <Flex tag={Flex} alignItems="center">
    <Avatar {...avatar} />
    <Flex className="ms-2">
      <h5 className="mb-0 fs--1" title={status}>
        {firstname} {lastname}
      </h5>
    </Flex>
  </Flex>
)

export const addrFormatter = (dataField, { address_street, address_rest }) => {
  const content = (
    <div style={{ lineHeight: '15px' }}>
      {address_street}
      <br />
      {address_rest}
    </div>
  )
  const address = address_street ? 'https://google.com/maps/search/' + address_street.replace(/ /g, '+') + ' ' + address_rest.replace(/ /g, '+') : ''

  return address_street && address_street.length ? (
    <div
      onClick={() => {
        window.open(address)
      }}
      className="link-label"
    >
      {content}
    </div>
  ) : (
    dataField
  )
}

export const telFormatter = (rowContent) => {
  const tellink = rowContent && rowContent.length ? `<a href="tel:${rowContent}">${rowContent}</a>` : rowContent
  return <div dangerouslySetInnerHTML={{ __html: tellink }} />
}

export const hrsFormatter = (rowContent, row) => {
  return <span style={{ color: row.minscol }}>{rowContent}</span>
}

export const daysbehindFormatter = (cell, row) => {
  const color = +cell > 100 ? 'red' : cell < 50 ? 'green' : ''
  return (
    <span style={{ color }} title={row.sess_info?.replaceAll('<br>', '\n')}>
      {cell}
    </span>
  )
}

// const isValidEmail = (email) => {
//   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   return emailRegex.test(email);
// }
export const validEmail = (email) => {
  const re = /^\S+@\S+\.\S+$/

  // const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  // const re =
  // eslint-disable-next-line
  // /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
  // var re =
  //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const emailFormatter = (rowContent, row) => {
  const link = rowContent && rowContent?.length ? `<a href="mailto:${rowContent}">${rowContent}</a>` : rowContent
  return <div dangerouslySetInnerHTML={{ __html: link }} />
}

export const userEmailFormatter = (rowContent, row) => {
  // if (!rowContent) return ''
  const link =
    rowContent && rowContent?.length
      ? `<a href="mailto:${rowContent}">${rowContent}</a>`
      : row?.username?.length && !rowContent?.length
      ? 'no email'
      : rowContent

  return rowContent === row.username && link?.length ? (
    <SoftBadge pill bg={+row.silentlogin ? 'primary' : 'success'} className="rounded-capsule fs--1 d-block">
      <div dangerouslySetInnerHTML={createMarkup(link)}></div>
    </SoftBadge>
  ) : rowContent?.trim().toUpperCase() !== row?.username?.trim().toUpperCase() && row?.username?.length ? (
    <SoftBadge pill bg="danger" className="rounded-capsule fs--1 d-block">
      <div title={`Username mismatch (${row.username}).  Use Verify button.`} dangerouslySetInnerHTML={createMarkup(link)}></div>
    </SoftBadge>
  ) : link?.length ? (
    <div dangerouslySetInnerHTML={{ __html: link }} />
  ) : null
}

export const studentFormatter = (cell, row, rowIndex, formatExtraData) => {
  return row?.studata.map((c, k) => (
    <Link key={k} to={`/people/students/${c.sid}`}>
      <h5 className="mb-0 fs--1">{c.stufullname}</h5>
    </Link>
  ))
}

export const responseOptions = [
  { value: '', label: 'No Response' },
  {
    value: '0',
    label: <SoftBadge bg="danger">Declined</SoftBadge>
  },
  {
    value: '1',
    label: <SoftBadge bg="success">Interested</SoftBadge>
  }
]
const yo = () => {
  const startYear = new Date().getFullYear()
  let options = []
  for (let index = 0; index < 12; index++) {
    options.push({
      value: (startYear + index - 2000).toString(),
      label: startYear + index
    })
  }
  return options
}

export const ccYearOptions = yo()

export const groupSessionTypes = (types) => {
  return [
    {
      label: 'Filters',
      options: types.filter((t) => t.type === 'filter')
    },
    {
      label: 'Session Types',
      options: types.filter((t) => t.type !== 'filter')
    }
  ]
}

export const defaultNewStaff = {
  firstname: '',
  lastname: '',
  location: { label: '' }
}

export const goMainnav = async (location, setTutorOptions, getRest = '') => {
  const parts = (typeof location === 'string' && location?.split('-')) || [location]

  let j

  if (parts?.length > 1) {
    j = { cboDistrict: parts[1], cboSchool: parts[0] }
  } else {
    j = { cboSchool: parts[0], excludeAll: true }
  }

  const response = await APIService.post('func/mainnav', j)

  const tutOpts = response.tutors.map((t) => ({
    ...t,
    value: t.id,
    label: t.nameonly,
    isDisabled: !['Active', 'Ready'].includes(t?.status)
  }))
  setTutorOptions(tutOpts)
  if (typeof getRest === 'function') {
    getRest(response)
  }
}

export const goUrl = (url) => {
  if (isValidHttpUrl(url)) window.open(url, '_blank')
  else toast.error('Not a valid URL.  Should take the form: https://example.com')
}

export const isValidHttpUrl = (string) => {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const copyTextToClipBoard = async (content) => {
  await navigator.clipboard.writeText(content)
}

export const sendToMailchimp = async (sendType, recordid) => {
  if (!store.session.value.mailchimp_apikey?.length && !+store.session.value.webhooks) return
  const response = await APIService.post('apim/send_to_mailchimp', { sendType, recordid })
  if (response.success) {
    toast.info(`Mailchimp ${sendType} completed`)
  } else {
    if (response.exception === 'Mailchimp dev abort') toast(response.exception, { theme: 'light' })
    else toastError(response.exception)
  }
}

export const customOperation = (op) => {
  return new Promise(async (resolve, reject) => {
    const response = await APIService.post('apim/custom_operation', { op })

    if (!response.success) {
      toastError(response.exception)
      reject(response.exception)
    } else {
      resolve(response)
    }
  })
}

export function quickRecordDelete(table, recordid) {
  APIService.post('tp/quickDel', {
    table,
    recordid
  }).then((response) => {
    if (!response.success) {
      toastError(response.exception)
    }
  })
}

export const quickFieldRead = ({ table, fieldname, recordid }) => {
  return APIService.post('func/quickFieldRead', {
    table,
    fieldname,
    recordid
  })
}
export const quickFieldSave = (table, recordid, field, value, transform, readOnly) => {
  return new Promise(async (resolve, reject) => {
    if (readOnly) {
      toast.error("This can't be modified.")
      reject("This can't be modified.")
      return false
    }
    const response = await APIService.post('tp/quickFieldSave', {
      table,
      recordid,
      field,
      value,
      transform
    })
    if (!response.success) {
      toastError(response.exception)
      reject(response.exception)
    } else {
      resolve(response)
    }
  })
}

export function multiFieldSave(table, wherefields, wherevalues, field, value, transform, readOnly) {
  if (readOnly) {
    toast.error("This can't be modified.")
    return
  }
  APIService.post('tp/multiFieldSave', {
    table,
    wherefields,
    wherevalues,
    field,
    value,
    transform
  }).then((response) => {
    if (!response.success) {
      toastError(response.exception)
    }
  })
}

export const postData = (defaultTable, keyField, keyValue, data, extraData = []) => {
  return APIService.post('apim/save_data/', {
    defaultTable,
    keyField,
    keyValue,
    data,
    extraData
  })
}

export const resendCode = async () => {
  const response = await APIService.post('login/send_2fa_email')
  if (response.success) {
    toast.success('Code resent')
  } else {
    toastError(response.exception)
  }
}

export const IsJsonString = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const safeJsonParse = (str) => {
  let json
  try {
    json = JSON.parse(str)
  } catch (e) {
    return false
  }
  return json
}

export const CustomDateInput = forwardRef((props, ref) => {
  const {
    name = 'dateinput',
    label,
    placeholder = store.session.value.dateFormat,
    placeHolder,
    value,
    onClick,
    style,
    disabled,
    showLabel = true,
    overrideValue,
    fullWidth = true,
    width = null,
    onChange,
    ...rest
  } = props

  const thisLabel = label && label.length ? label : placeholder

  const place = placeHolder || placeholder

  const inputField = (
    <Form.Control
      type="text"
      ref={ref}
      name={name}
      value={overrideValue || value}
      placeholder={place}
      style={{ ...style, width: fullWidth ? '100%' : width || 'auto' }}
      className={classNames('shadow-none')}
      disabled={disabled}
      onClick={onClick}
      onChange={onChange}
      {...rest}
    />
  )

  return showLabel ? (
    <FloatingLabel controlId={name} label={thisLabel} style={{ pointerEvents: 'all' }}>
      {inputField}
    </FloatingLabel>
  ) : (
    inputField
  )

  // <div className={showLabel ? 'form-label-group' : ''}>
  //   <input
  //     ref={ref}
  //     className="form-control oas-margin-top"
  //     type="text"
  //     value={overrideValue || value}
  //     style={{ ...style, width: fullWidth ? '100%' : 'auto' }}
  //     disabled={disabled}
  //     onClick={onClick}
  //     onChange={(e) => {
  //       console.log('e', e.target.value)
  //       onChange(e)
  //     }}
  //   />
  //   {showLabel && <label>{thisLabel}</label>}
  // </div>
})

export const findLabel = (list, itemIndex, nullLabel = 'None Selected') => {
  const item = list.find((x) => +x.value === +itemIndex || x.value === itemIndex)
  return item ? item.label : nullLabel
}

const SelectRowInput = ({ indeterminate, rowIndex, ...rest }) => (
  <Form.Check
    type="checkbox"
    onChange={() => {}}
    ref={(input) => {
      if (input) input.indeterminate = indeterminate
    }}
    {...rest}
  />
)

// export const NoDataIndicationTS = ({ loading = false, label = 'No Data', my = 5, noData = false }) => {
//   const [showLabel, setShowLabel] = useState(false)

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowLabel(true)
//     }, 250)

//     return () => clearTimeout(timer)
//   }, [])

//   return loading ? (
//     <Loader loading={loading} />
//   ) : (
//     <Row className={`text-center my-${my}`}>
//       <Col>{showLabel && <h5 className="text-400">{label}</h5>}</Col>
//     </Row>
//   )
// }

export const NoDataIndicationTS = ({ loading = false, label = 'No Data', my = 5 }) => {
  const [delayed, setDelayed] = useState(false)

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => setDelayed(true), 500)
      return () => clearTimeout(timer)
    } else {
      setDelayed(false)
    }
  }, [loading])

  return loading ? (
    <Loader loading={loading} />
  ) : delayed ? (
    <Row className={`g-0 text-center my-${my}`}>
      <Col>
        <h5 className="text-400">{label}</h5>
      </Col>
    </Row>
  ) : (
    <Row className={`g-0 text-center my-${my}`}>
      <Col>&nbsp;</Col>
    </Row>
  )
}

export const NoDataIndication = ({ loading = false, label = 'No Data', my = 5 }) =>
  loading ? (
    <Loader loading={loading} />
  ) : (
    <Row className={`text-center my-${my}`}>
      <Col>
        <h5 className="text-400">{label}</h5>
      </Col>
    </Row>
  )

export const safeFormatDate = (date = new Date(), fmt = store.session.value.dateFormat) => {
  try {
    return format(date, fmt)
  } catch (error) {
    return null
  }
}

export const safeFormatTime = (date = new Date(), fmt = store.session.value.timeFormat) => {
  try {
    return format(date, fmt)
  } catch (error) {
    return null
  }
}
export const safeParseDate = (date, format = store.session.value.dateFormat) => {
  try {
    let ret = parse(date, format, new Date())
    if (isNaN(ret)) ret = parse(date, 'yyyy-MM-dd', new Date())

    // If the year is more than 20 years in the future, subtract 100 years
    if (ret && ret.getFullYear() > new Date().getFullYear() + 20) {
      ret.setFullYear(ret.getFullYear() - 100)
    }
    return isNaN(ret) ? null : ret
  } catch (error) {
    return null
  }
}
export const safeParseTime = (time, format = store.session.value.timeFormat) => {
  try {
    let ret = parse(time, format, new Date())
    if (isNaN(ret)) ret = parse(time, 'hh:mm aa', new Date())
    return isNaN(ret) ? null : ret
  } catch (error) {
    return null
  }
}

export const safeLength = (str) => {
  return (str && str.length) || 0
}

// deselect all checkboxes
export const deSelectAll = (ref) => {
  if (!ref.current || !ref?.current?.selectionContext) return
  ref.current.selectionContext.selected = []
  ref.current.forceUpdate()
}

export const selectRow = (onSelect, clickToExpand = true) => ({
  mode: 'checkbox',
  columnClasses: 'py-2 align-middle',
  clickToSelect: false,
  selectionHeaderRenderer: ({ mode, rowKey, ...rest }) => <SelectRowInput name="selectionHeaderRenderer" type="checkbox" {...rest} />,
  selectionRenderer: ({ mode, rowKey, ...rest }) => <SelectRowInput name={rowKey.toString()} type={mode} {...rest} />,
  headerColumnStyle: { border: 0, verticalAlign: 'middle' },
  selectColumnStyle: { border: 0, verticalAlign: 'middle' },
  onSelect: onSelect,
  onSelectAll: onSelect,
  clickToExpand
})

export const selectRowCbTop = (onSelect, clickToExpand = true) => ({
  mode: 'checkbox',
  columnClasses: 'py-2 align-middle',
  clickToSelect: false,
  selectionHeaderRenderer: ({ mode, rowKey, ...rest }) => <SelectRowInput type="checkbox" {...rest} />,
  selectionRenderer: ({ mode, rowKey, ...rest }) => <SelectRowInput type={mode} {...rest} />,
  headerColumnStyle: { border: 0, verticalAlign: 'middle' },
  selectColumnStyle: { border: 0 },
  onSelect: onSelect,
  onSelectAll: onSelect,
  clickToExpand
})

export const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    <span className="fs--2 ms-1">
      Showing {from} to {to} of {size} Results
    </span>
  </span>
)

export const gridOptions = {
  showTotal: true,
  paginationTotalRenderer: customTotal
}

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
  <Dropdown as="span" className="mt-3">
    <Dropdown.Toggle variant="light" size="sm">
      {+currSizePerPage === 999999 ? 'All' : currSizePerPage}
    </Dropdown.Toggle>
    <Dropdown.Menu container={document.body}>
      {options.map((option, key) => {
        return (
          <Dropdown.Item
            key={key}
            onMouseDown={(e) => {
              e.preventDefault()
              onSizePerPageChange(option.page)
            }}
          >
            {option.text}
          </Dropdown.Item>
        )
      })}
    </Dropdown.Menu>
  </Dropdown>
)
const sizePerPageRendererModal = ({ options, currSizePerPage, onSizePerPageChange }) => (
  <Dropdown>
    <Dropdown.Toggle variant="light" size="sm">
      {+currSizePerPage === 999999 ? 'All' : currSizePerPage}
    </Dropdown.Toggle>
    <Dropdown.Menu /* container={document.body}  */>
      {options.map((option, key) => {
        return (
          <Dropdown.Item
            key={key}
            onMouseDown={(e) => {
              e.preventDefault()
              onSizePerPageChange(option.page)
            }}
          >
            {option.text}
          </Dropdown.Item>
        )
      })}
    </Dropdown.Menu>
  </Dropdown>
)

export const paginationOptionsInvoicing = (gridParams, totalSize) => {
  return {
    page: gridParams.page,
    sizePerPage: gridParams.sizePerPage,
    totalSize: parseInt(totalSize, 10),
    showTotal: true,
    sizePerPageList: [
      {
        text: '15',
        value: 15
      },
      {
        text: '50',
        value: 50
      },
      {
        text: '100',
        value: 100
      },
      {
        text: '500',
        value: 500
      },
      {
        text: 'All',
        value: parseInt(totalSize, 10)
      }
    ],
    paginationTotalRenderer: customTotal,
    sizePerPageRenderer
  }
}

export const paginationOptionsMain = (gridParams, totalSize, isModal = false) => {
  return {
    page: gridParams.page,
    sizePerPage: gridParams.sizePerPage,
    totalSize: +totalSize,
    showTotal: true,
    sizePerPageList: [
      {
        text: '10',
        value: 10
      },
      {
        text: '50',
        value: 50
      },
      {
        text: '100',
        value: 100
      },
      {
        text: '250',
        value: 250
      },
      {
        text: '500',
        value: 500
      },
      {
        text: '1000',
        value: 1000
      },
      {
        text: 'All',
        value: 999999
        // value: +totalSize
      }
    ].filter((f) => f.text !== 'All' || store.session.value.authlevel < 2),
    paginationTotalRenderer: customTotal,
    sizePerPageRenderer: isModal ? sizePerPageRendererModal : sizePerPageRenderer
  }
}

export const sizePerPageList = [
  {
    label: '5',
    value: 5
  },
  {
    label: '10',
    value: 10
  },
  {
    label: '50',
    value: 50
  },
  {
    label: '100',
    value: 100
  },
  {
    label: '250',
    value: 250
  },
  {
    label: '500',
    value: 500
  },
  {
    label: '1000',
    value: 1000
  },
  {
    label: 'All',
    value: 999999
  }
]

export const productsGenerator = (quantity = 5, callback) => {
  if (callback) return Array.from({ length: quantity }, callback)

  // if no given callback, retrun default product format.
  return Array.from({ length: quantity }, (value, index) => ({
    id: index,
    name: `Item name ${index}`,
    price: 2100 + index
  }))
}

export const sortCaret = (order, column) => {
  if (!order) return ''
  else return <FontAwesomeIcon className="ms-1" icon="caret-down" color="#999" transform={`rotate-${order === 'asc' ? 180 : 0})`} />
}

export const sendSlackMessage = (text, blocks = false, channel = 'charles') => {
  let payload = {
    text
  }
  let body = `payload=${JSON.stringify(payload)}`

  // let body = `payload=${encodeURI(JSON.stringify(payload))}`
  // charles channel
  const hookURLs = {
    charles: 'https://hooks.slack.com/services/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',
    support: 'https://hooks.slack.com/services/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',
    sales: 'https://hooks.slack.com/services/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',
    system: 'https://hooks.slack.com/services/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',
    magenta: 'https://hooks.slack.com/services/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',
    osprey: 'https://hooks.slack.com/services/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'
  }
  // console.log('hookURLs[', channel, hookURLs[channel])
  const hook = hookURLs[window.location.href.includes('localhost') ? 'charles' : channel]
  console.log('hook', hook)

  window.location.href.includes('localhost')
    ? '' //console.error('Slack Webhook', text)
    : fetch(hook, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: blocks ? JSON.stringify(text) : body
      })
}

export const joditButtons = [
  'bold',
  'strikethrough',
  'underline',
  'italic',
  'eraser',
  // '|',
  // 'superscript',
  // 'subscript',
  '|',
  'ul',
  'ol',
  '|',
  'outdent',
  'indent',
  '|',
  'font',
  'fontsize',
  'brush',
  'paragraph',
  '|',
  'image',
  // 'file',
  // 'video',
  'table',
  'link',
  '|',
  'align',
  // 'undo',
  // 'redo',
  // '\n',
  // 'selectall',
  // 'cut',
  // 'copy',
  // 'paste',
  // 'copyformat',
  // '|',
  // 'hr',
  // '|',
  'print',
  'fullsize',
  'source'
  // 'symbol',
  // 'preview',
  // 'find',
  // 'about'
]

export const joditMinimalButtons = [
  'bold',
  'strikethrough',
  'underline',
  'italic',
  'eraser',
  '|',
  'ul',
  'ol',
  '|',
  'font',
  'fontsize',
  'brush',
  'paragraph',
  'table',
  '|',
  'align',
  'print',
  'link',
  'source'
  // 'preview',
  // 'find',
  // 'about'
]
export const XjoditMinimalButtons = [
  'bold,strikethrough,underline,italic,ul,ol,|,font,fontsize,brush,paragraph,|,align,print,source'
  // 'bold,strikethrough,underline,italic,eraser,|,ul,ol,|,font,fontsize,brush,paragraph,|,align,print,source'
]

export const joditConfig = {
  readonly: false,
  toolbarButtonSize: 'small',
  defaultActionOnPaste: 'insert_clear_html',
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  defaultActionOnPasteFromWord: 'insert_clear_html',
  beautifyHTML: true,
  buttons: joditMinimalButtons,
  placeholder: '',
  showXPathInStatusbar: false,
  showWordsCounter: false,
  showCharsCounter: false,
  toolbarAdaptive: false, // from tp
  minHeight: 120,
  editorCssClass: 'bg-white',
  uploader: { insertImageAsBase64URI: true },
  link: { processPastedLink: false },
  // uploader: { url: 'none' },
  spellcheck: true,
  disablePlugins: 'add-new-line,wrap-nodes'

  // enter: 'br'
}

export const userPermission = (key) => {
  const keyval = store.session.value?.permissions[key]
  if (!keyval) return 1
  if (keyval.includes('visible')) return false
  if (keyval.includes('disable')) return 2
  return 1
}

export const getSessvar = (key, defaultValue = '') => {
  return store.session.value?.[key] ?? defaultValue
}

export const getS3Object = (S3Key, filename) => {
  const fetchFrom = `https://${store.session.value.aws_store}.s3.${store.session.value.aws_region}.amazonaws.com/${S3Key}`

  return fetch(fetchFrom)
    .then((res) => res.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob)
      let link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
      URL.revokeObjectURL(link.href)
    })
}

export const loadDoc = async (doc) => {
  if (doc.filename === '~LINK~') {
    window.open((doc.storefilename.includes('http') ? '' : APIService.getServer() + '/') + doc.storefilename)
  } else {
    await getS3Object(doc.storefilename, doc.filename)
    toast.success('File downloaded')
  }
}

// template literal
const badgeOptions = {
  1: <BadgeFormatter>Yes</BadgeFormatter>,
  0: <BadgeFormatter color="danger">No</BadgeFormatter>,
  yes: <BadgeFormatter>Yes</BadgeFormatter>,
  on: <BadgeFormatter>Yes</BadgeFormatter>,
  no: <BadgeFormatter color="danger">No</BadgeFormatter>,
  '': <BadgeFormatter color="danger">No</BadgeFormatter>,
  default: <BadgeFormatter color="warning">Not Set</BadgeFormatter>
}

const switchFn =
  (lookupObject, defaultCase = 'default') =>
  (ex) =>
    lookupObject[ex] || lookupObject[defaultCase]

export const badgeSwitch = switchFn(badgeOptions)

export const downloadFile = async (file) => {
  try {
    const url = `${APIService.getServer()}/func/DownloadFile/${file}`
    // const url = `${APIService.getServer()}/func/DownloadFile/?file=${file}`
    await window.open(url, '_blank')
  } catch (error) {
    console.log('error', error)
  }
}

export const YYYdownloadFile = (file) => {
  return new Promise((resolve, reject) => {
    const url = `${APIService.getServer()}/func/downloadFile/?file=${file}`
    window.open(url, '_blank')
    resolve()
  })
}
export const XXXdownloadFile = (file) => {
  return new Promise((resolve, reject) => {
    var iframe = document.createElement('iframe')
    iframe.setAttribute('src', `${APIService.getServer()}/func/downloadFile/?file=${file}`)
    iframe.style.width = '0px'
    iframe.style.height = '0px'
    iframe.style.display = 'none'
    iframe.onload = function () {
      resolve()
    }
    document.body.appendChild(iframe)
  })
}

export const ShoomHd = (props) => {
  const { title, headerDescription } = props

  return (
    <div className="py-1 flex-grow-1">
      <h5 className="text-primary">
        <Row>
          <Col>
            <FontAwesomeIcon icon="palette" className="me-2 fs-0" />
            <span className="overflow-ellipsis">{title}</span>
          </Col>
          <Col xs="auto" className="text-end"></Col>
        </Row>
      </h5>
      <p className="mb-0 fs--1 text-primary opacity-75">{headerDescription}</p>
    </div>
  )
}

export const ShoomFt = (props) => {
  const { closeMethod, closeLabel = 'Close', saveMethod, saveLabel = 'Save' } = props

  return (
    <Row>
      <Col className="border-top pt-3 bg-200 text-end">
        <Button size="sm" variant="outline-secondary" onClick={() => closeMethod()}>
          {closeLabel}
        </Button>
        {saveMethod && (
          <Button variant="primary" onClick={() => saveMethod()}>
            {saveLabel}
          </Button>
        )}
      </Col>
    </Row>
  )
}

export const LabelIcon = ({ onClick, icon, className = '', title, transform = '' }) => (
  <div className="btn btn-light btn-sm cursor-pointer" style={{ padding: '0 5px 0 5px' }} onClick={onClick} title={title}>
    <FontAwesomeIcon icon={icon} className={classNames(className, { 'text-500': !!!className.length })} transform={transform} />
  </div>
)

export const ExportCSVButton = (props) => {
  const handleClick = () => {
    props.onExport()
  }
  return (
    <div>
      <Button size="sm" variant="outline-primary" onClick={handleClick}>
        <FontAwesomeIcon icon="external-link-alt" /> Export
      </Button>
    </div>
  )
}

export const ExportCSVLabelIcon = (props) => {
  const handleClick = () => {
    props.onExport()
  }
  return <LabelIcon onClick={handleClick} icon="file-csv" title="Export to CSV" />
}
export const ExportCSVIcon = (props) => {
  const handleClick = () => {
    props.onExport()
  }
  return (
    <FontAwesomeIcon className="text-500 link-hover me-3" transform="down-7 grow-2" icon="file-csv" title="Export to CSV" onClick={handleClick} />
  )
}

export const ExportExcelIconGrp = (props) => {
  const handleClick = () => {
    props.onExport(props)
  }
  return <ActionButton icon="file-excel" tooltip="Export to Excel" onClick={handleClick} />
}

export const ExportClipboardIconGrp = (props) => {
  const copyToClipboard = (props) => {
    const { search, columns, data } = props
    const searchText = search?.searchText?.toLowerCase()
    const filteredColumns = columns.filter(({ csvExport }) => csvExport !== false).map((col) => ({ ...col, text: convertToPlain(col.text) }))
    const newLine = navigator.userAgent.match(/Windows/) ? '\r\n' : '\n'
    const headers = filteredColumns.map(({ text }) => text).join('\t') + newLine

    const rows = data
      .filter((row, rowIndex) => {
        return searchText
          ? filteredColumns.some(({ dataField, csvFormatter, formatExtraData }) => {
              let cell = row[dataField]
              cell = csvFormatter ? csvFormatter(cell, row, rowIndex, formatExtraData) : cell
              cell = Array.isArray(cell) ? cell.join(', ') : cell
              cell = (cell || '').toLowerCase()
              return cell && cell.includes(searchText)
            })
          : true
      })
      .map((row, rowIndex) =>
        filteredColumns.reduce((acc, { text, dataField, csvFormatter, formatExtraData }) => {
          let value = row[dataField]

          value = csvFormatter ? csvFormatter(value, row, rowIndex, formatExtraData) : value
          value = Array.isArray(value) ? value.join(',') : value ? value : ''
          return { ...acc, [text]: convertToPlain(value) }
        }, {})
      )
      .map((r) => Object.values(r).join('\t').replaceAll('\n', ''))
      .join(newLine)

    copyTextToClipBoard(headers + rows)
    toast.success(`${data?.length} row${data?.length === 1 ? '' : 's'} copied to clipboard`)
  }
  return <ActionButton tooltip="Copy to Clipboard" onClick={() => copyToClipboard(props)} icon="copy" />
}

export const ExportClipboardIconGrpTS = ({ search = '', columns, data }) => {
  const copyToClipboard = () => {
    const searchText = search?.searchText?.toLowerCase()
    const filteredColumns = columns.filter(({ csvExport }) => csvExport !== false).map((col) => ({ ...col, text: extractHeaderText(col.header) }))
    const newLine = navigator.userAgent.match(/Windows/) ? '\r\n' : '\n'
    const headers = filteredColumns.map(({ text }) => text).join('\t') + newLine

    const rows = data
      .filter((row, rowIndex) => {
        return searchText
          ? filteredColumns.some(({ dataField, csvFormatter, formatExtraData }) => {
              let cell = row[dataField]
              cell = csvFormatter ? csvFormatter(cell, row, rowIndex, formatExtraData) : cell
              cell = Array.isArray(cell) ? cell.join(', ') : cell
              cell = (cell || '').toLowerCase()
              return cell && cell.includes(searchText)
            })
          : true
      })
      .map((row, rowIndex) =>
        filteredColumns.reduce((acc, { text, accessorKey, csvFormatter, formatExtraData }) => {
          let value = row[accessorKey]

          value = csvFormatter ? csvFormatter(value, row, rowIndex, formatExtraData) : value
          value = Array.isArray(value) ? value.join(',') : value ? value : ''
          return { ...acc, [text]: convertToPlain(value) }
        }, {})
      )
      .map((r) => Object.values(r).join('\t').replaceAll('\n', ''))
      .join(newLine)

    copyTextToClipBoard(headers + rows)
    toast.success(`${data?.length} row${data?.length === 1 ? '' : 's'} copied to clipboard`)
  }
  return <ActionButton tooltip="Copy to Clipboard" onClick={() => copyToClipboard()} icon="copy" />
}

export const ExportExcelIconGrpTS = ({ search = '', columns, data, filename = 'export', xtraRef = null }) => {
  return (
    <ActionButton
      tooltip="Excel Export"
      onClick={() => gridExcelExport({ meta: { filename }, columns, data, xtra: xtraRef?.current })}
      icon="file-excel"
    />
  )
}

export const ExportExcelIcon = (props) => {
  const handleClick = () => {
    props.onExport()
  }

  return (
    <FontAwesomeIcon className="text-500 link-hover me-3" transform="down-7 grow-2" icon="file-excel" title="Export to Excel" onClick={handleClick} />
  )
}

export const ExportCSVIconFlat = (props) => {
  const handleClick = () => {
    props.onExport()
  }
  return <FontAwesomeIcon className="text-500 link-hover me-3" transform="grow-2" icon="file-csv" title="Export to CSV" onClick={handleClick} />
}

export const ExportCSV = (props) => {
  const handleClick = () => {
    props.onExport()
  }
  return (
    <IconButton icon="external-link-alt" transform="shrink-3 down-2" variant="falcon-default" size="sm" className="ms-2" onClick={handleClick}>
      Export
    </IconButton>
  )
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0
  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}

export const loadLocStore = (key) => {
  const storeData = safeJsonParse(localStorage.getItem('oases-' + store.session.value.username))
  if (!storeData || !storeData[key]) return null
  return storeData[key]
}

const getKeyParams = (key) => {
  switch (key) {
    case 'tm':
      return { maxItems: 20, filterKey: 'uri' }
    default:
      return { maxItems: 2, filterKey: '' }
  }
}

export const addConfigToStore = (key, items, overrideUrlFragment = '') => {
  if (!items.uri) items.uri = new URL(window.location.href).pathname
  const urlFragment = overrideUrlFragment ? overrideUrlFragment : window.location.hash.slice(1)
  if (!items.timestamp) items.timestamp = Date.now()

  const keyParams = getKeyParams(key)
  let locStore = safeJsonParse(localStorage.getItem('oases-' + store.session.value.username))
  if (!locStore) locStore = []
  if (!locStore[key]) locStore[key] = []
  let keyedItems = locStore[key]
  keyedItems.splice(keyParams.maxItems - 1, 10)
  keyedItems = keyedItems.filter((i) => !i[keyParams.filterKey].includes(items[keyParams.filterKey]))
  items[keyParams.filterKey] += '#' + urlFragment
  keyedItems.unshift({ ...items })
  localStorage.setItem('oases-' + store.session.value.username, JSON.stringify({ ...locStore, [key]: keyedItems }))
}

export const addToGridsStore = (grid, items) => {
  if (!items.timestamp) items.timestamp = Date.now()
  let locStore = safeJsonParse(localStorage.getItem('oases-' + store.session.value.username))
  if (!locStore) locStore = {}
  if (!locStore.grids) locStore.grids = {}
  locStore.grids[grid] = items
  localStorage.setItem('oases-' + store.session.value.username, JSON.stringify(locStore))
}

export const deleteStoreKey = (key) => {
  let locStore = safeJsonParse(localStorage.getItem('oases-' + store.session.value.username))
  if (!locStore) return
  const { [key]: junk, ...remaining } = locStore
  localStorage.setItem('oases-' + store.session.value.username, JSON.stringify(remaining))
}

// export const defaultGridParams = ({ show = [], sizePerPage = 10, ...rest }) => ({
//   pageIndex: 1,
//   pageSize: sizePerPage,
//   sortField: '',
//   sortOrder: '',
//   show,
//   edtFilter: '',
//   showFilters: false,
//   sa: [],
//   subjLevData: [],
//   ...rest
// })

export const defaultGridParamsTS = ({ show = [], pageSize = 10, ...rest } = {}) => ({
  sortField: '',
  sortOrder: '',
  show,
  edtFilter: '',
  showFilters: false,
  sa: [],
  subjLevData: [],
  // for tanstack
  sortBy: null,
  sortDirection: null,
  pageIndex: 0,
  pageSize,
  ...rest
})

export const goZendesk = async (formRef = null, inputRef = null) => {
  if (+store?.session?.value?.authlevel === 1 && inputRef) {
    const response = await APIService.post('func/go_zendesk')
    if (response.success) {
      inputRef.current.value = response.jwt
      formRef.current.submit()
    } else {
      toast.error(response.exception)
    }
  } else {
    window.open('https://oases.zendesk.com/hc/en-us', '_blank')
  }
}

/**
 * Return the fixed point notation.
 * @param {number} n - the number to be fixed notation
 * @param {number} d - the number of digits after decimal points
 * @returns {string}
 */
export const oasToFixed = (n, d) => {
  var tenToD = Math.pow(10, d)
  return (Math.floor(n * tenToD + 0.5) / tenToD).toFixed(d)
}

export const formatCurrency = (number, currency) => {
  return `${currency}${parseFloat(number)
    .toFixed(2)
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`
}

// react-select styling
export const selectStylesMultiLabel = {
  control: (base) => ({ ...base, minHeight: 58 }),
  valueContainer: (base) => ({ ...base, paddingTop: 15 }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }) // adjust this value as needed
}

export const oasSelectLabel = {
  control: (base) => ({ ...base, minHeight: 58 }),
  valueContainer: (base) => ({ ...base, paddingTop: 15 }),
  singleValue: (base) => ({ ...base, top: '70%', left: 10 }),
  placeholder: (base) => ({ ...base, top: '70%', left: 10 }),
  option: (base, { data, isSelected, isDisabled }) => ({
    ...base,
    padding: '2px 12px',
    // color: isSelected ? 'blue' : data.color ?? '#555'
    color: isSelected ? 'blue' : isDisabled ? '#333' : '#333',
    backgroundColor: isSelected ? 'lightgray !important' : base.backgroundColor
  }),
  dropdownIndicator: (base) => ({ ...base, padding: '2px 2px' }),
  clearIndicator: (base) => ({ ...base, padding: '2px 2px' }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 })
}

export const oasSelectMinimal = {
  control: (base) => ({ ...base, minHeight: 32 }), // add height: 30 for exact height if not isMulti
  singleValue: (base) => ({ ...base, top: 15 }),
  placeholder: (base) => ({ ...base, top: 15 }),
  option: (base, { data, isSelected, isDisabled }) => ({
    ...base,
    padding: '2px 12px',
    // color: isSelected ? 'blue' : data.color ?? '#555'
    color: isSelected ? 'blue' : isDisabled ? '#999' : '#333'
  }),
  // option: (base) => ({ ...base, padding: '2px 12px' }),
  dropdownIndicator: (base) => ({ ...base, padding: '2px 2px' }),
  clearIndicator: (base) => ({ ...base, padding: '2px 2px' }),
  // container: (css) => ({...css, zIndex: 1000 }), took this out because of additional tutors combos in session details.
  menuPortal: (base) => ({ ...base, zIndex: 1001 }) //took this out because of the filter combos in student grid
}

export const oasSelectMinimalProgram = {
  // option: (provided, state) => ({
  //   ...provided,
  //   borderBottom: '1px dotted pink',
  //   color: state.isSelected ? 'red' : 'blue',
  //   padding: 20
  // }),
  option: (provided, { data, isSelected }) => ({
    ...provided,
    // color: data.value === '999999' ? (isSelected ? '#fff' : 'green') : 'blue',
    color: isSelected ? 'blue' : '#777',
    fontWeight: data.value.includes('999999') ? 'bold' : '',
    padding: '2px 12px'
  }),

  // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //   const color = chroma(data.color);
  //   return {
  //     ...styles,
  //     backgroundColor: isDisabled
  //       ? undefined
  //       : isSelected
  //       ? data.color
  //       : isFocused
  //       ? color.alpha(0.1).css()
  //       : undefined,
  //     color: isDisabled
  //       ? '#ccc'
  //       : isSelected
  //       ? chroma.contrast(color, 'white') > 2
  //         ? 'white'
  //         : 'black'
  //       : data.color,
  //     cursor: isDisabled ? 'not-allowed' : 'default',

  //     ':active': {
  //       ...styles[':active'],
  //       backgroundColor: !isDisabled
  //         ? isSelected
  //           ? data.color
  //           : color.alpha(0.3).css()
  //         : undefined,
  //     },
  //   };
  // },
  // option: (provided, state) => ({
  //   ...provided,
  //   borderBottom: '1px dotted pink',
  //   color: false ? 'red' : 'blue',
  //   padding: 20
  // }),
  control: (base) => ({ ...base, minHeight: 32 }), // add height: 30 for exact height if not isMulti
  singleValue: (base) => ({ ...base, top: 15 }),
  placeholder: (base) => ({ ...base, top: 15 }),
  // option: (base) => ({ ...base, padding: '2px 12px' }),
  dropdownIndicator: (base) => ({ ...base, padding: '2px 2px' }),
  clearIndicator: (base) => ({ ...base, padding: '2px 2px' }),
  // container: (css) => ({...css, zIndex: 1000 }), took this out because of additional tutors combos in session details.
  menuPortal: (base) => ({ ...base, zIndex: 1001 }) //took this out because of the filter combos in student grid
}

export const programsAndLocations = (districts, locations) => {
  return districts
    .map((d) => {
      const all = locations.filter((f) => f.distid === d.distid)
      return [{ ...d, value: `${d.value}-${d.distid}` }, ...all]
    })
    ?.flat()
}

export const changeArrayKeys = (fromArray) => {
  let ret = []

  for (let row in fromArray) {
    ret.push({
      value: fromArray[row].id,
      label: fromArray[row].data ? fromArray[row].data : fromArray[row].name
    })
  }
  return ret
}

export function isAnswerCorrect(correctans, givenans) {
  var correct = correctans.split('~')
  var is_match = false
  givenans = givenans.toUpperCase()
  correct.forEach(function (item) {
    if (item === givenans || (typeof item !== 'string' && parseFloat(item) === parseFloat(givenans))) is_match = true
  })
  return is_match
}

export const getOptionFromNested = (options, value) => {
  let retval, result
  options.forEach((group) => {
    if (group.options) {
      result = group.options.find((f) => f.value === value)
    } else {
      result = group.value === value ? group : null
    }
    if (result) retval = result
  })
  return retval || { label: 'Unknown', value: '' }
}

export const getOptionFromNestedByLabel = (options, label, fallBack = '') => {
  let retval, result
  options.forEach((group) => {
    if (group.options) {
      result = group.options.find((f) => f.label === label.trim())
    } else {
      result = group.label === label.trim() ? group : null
    }
    if (result) retval = result
  })
  if (!retval && fallBack.length) {
    options.forEach((group) => {
      if (group.options) {
        result = group.options.find((f) => f.label === fallBack)
      } else {
        result = group.label === fallBack ? group : null
      }
      if (result) retval = result
    })
  }

  return retval || { label: 'Unknown', value: '' }
}

export function checkPhoneNumber(num) {
  // eslint-disable-next-line
  var mob = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
  return mob.test(num)
}

// htmltotext html2text
export const convertToPlain = (html) => {
  // Create a new div element
  var tempDivElement = document.createElement('div')
  // Set the HTML content with the given value
  tempDivElement.innerHTML = html
  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || ''
}

const _range = (n, m) => [...Array(m - n).keys()].map((k) => k + n)
export const range = (...args) => {
  let [from, to] = args
  if (to) {
    args = from > to ? [to, from] : [from, to]
    return _range(...args)
  }
  return _range(0, from)
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const isEmptyChart = (chart) => {
  let anyData = false
  chart?.datasets.forEach((dataset) => {
    if (dataset.data?.some((val) => val > 0)) anyData = true
  })
  return !anyData
}
export const isEmptyObject = (obj) => {
  if (!obj) return true
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const isEqualObject = (obj1, obj2) => {
  if (obj1 === obj2) return true

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  for (let key of keys1) {
    if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}

export const getNextIndex = (data) => {
  const indexes = data.map(({ index }) => parseInt(index))
  const highestIndex = Math.max(...indexes)
  return highestIndex === -Infinity ? 1 : highestIndex + 1
}

export const getFreeIndex = (data) => {
  const indexes = data.map(({ index }) => parseInt(index))
  const numArr = indexes.sort((a, b) => a - b)

  let result

  for (let i = 0; i < numArr.length; i++) {
    if (numArr[i] !== i + 1) {
      result = i + 1
      break
    }
  }

  if (!result) {
    result = numArr[numArr.length - 1] + 1
  }
  return result
}

export const StyledLink = styled.span`
  cursor: pointer;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 500;
  line-height: 1.2;
  color: $falcon-colored-link-1100-hover-color;
  &:hover {
    text-decoration: underline;
  }
`

export const saveLabel = async (lab_config, lab_name) => {
  await APIService.post('setup/savelabel', {
    lab_config,
    lab_name
  })
}

export const showTimeMin = (m) => {
  var fm = [
    Math.floor(m / 60), // HOURS
    Math.floor(m) % 60 // MINUTES
  ]
  return fm
    .map((v, i) => {
      return (v < 10 && i ? '0' : '') + v
    })
    .join(':')
}

export const addUniqueIdToData = (data) => {
  return data.map((r, i) => {
    // return { ...r, uid: Math.floor(Math.random() * 1000000) }
    return { ...r, uid: `c-${nanoid()}` }
  })
}

const knownURLs = [
  {
    company: 'tutormeeducation',
    comptla: 'tml',
    compname: 'Tutor Me Education',
    favicon: 'tml.png'
  }, // secure.tutormeeducation.com
  {
    company: 'intheloopnotes',
    comptla: 'tuu',
    compname: 'Learning Lab/Gateway Academy',
    favicon: 'tuu.ico'
  }, // secure.intheloopnotes.com
  {
    company: 'bcglearning',
    comptla: 'bls',
    compname: 'BCG Learning Specialists',
    favicon: 'favicon.ico'
  }, // mypage.bcglearning.com
  {
    company: 'fleet-tutorsonline',
    comptla: 'ftu',
    compname: 'Fleet Tutors',
    favicon: 'favicon.ico'
  }
]

export const isURLKnown = knownURLs.find((f) => window.location.href.includes(f.company))

export const calDateFormatter = (date) => {
  const options = { weekday: 'short', month: 'numeric', day: 'numeric' }
  const formatter = new Intl.DateTimeFormat(store.session.value.sys_lang, options)
  return formatter.format(date)
}

export const slotLabelFormat = () => {
  return store.session.value?.timeFormat === 'HH:mm'
    ? {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }
    : null // null gives the calendar default
  // : { hour: 'numeric', minute: '2-digit', meridiem: 'short' }
}

export const decryptSession = (ciphertext) => {
  var key = import.meta.env.VITE_SESSION_NR

  // Convert key and ciphertext into WordArrays
  var ciphertextWA = CryptoJS.enc.Base64.parse(ciphertext)
  var keyWA = CryptoJS.enc.Utf8.parse(key)

  // Separate IV, HMAC and ciphertext
  var ivWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(0, 4))
  var hmacWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4, 4 + 8))
  var actualCiphertextWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4 + 8))

  // Authenticate
  var hmacCalculatedWA = CryptoJS.HmacSHA256(actualCiphertextWA, keyWA)
  if (CryptoJS.enc.Base64.stringify(hmacCalculatedWA) === CryptoJS.enc.Base64.stringify(hmacWA)) {
    // Decrypt if authentication is successfull
    var decryptedMessageWA = CryptoJS.AES.decrypt({ ciphertext: actualCiphertextWA }, keyWA, { iv: ivWA })
    var decryptedMessage = CryptoJS.enc.Utf8.stringify(decryptedMessageWA)

    try {
      const json = JSON.parse(decryptedMessage)
      return json
    } catch (error) {
      console.error(error)
      return false
    }
  } else {
    console.log('Decrypt failed!')
    return false
  }
}

export const encryptObject = (obj) => {
  const secret_key = import.meta.env.VITE_TEST_SESSION_NR
  const iv = CryptoJS.lib.WordArray.random(16)
  const key = CryptoJS.enc.Utf8.parse(secret_key)
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(obj), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  const encryptedData = CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext))
  return encryptedData
}

export const getCookie = (name) => {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1)
    }
  }
  return null
}

export const sraFormatter = (cell, row, rowIndex) => {
  return row.sessid || row.id ? (
    <Flex direction="row">
      <SraIcon type="submit" truthy={row.submitted === '1' || row.sub === '1'} title="Submitted" cursor="default">
        S
      </SraIcon>
      <SraIcon type="review" truthy={row.reviewed === '1' || row.rev === '1'} title="Reviewed" cursor="default">
        R
      </SraIcon>
      <SraIcon type="approve" truthy={row.approved === '1' || row.app === '1'} title="Approved" cursor="default">
        A
      </SraIcon>
      {!!store.session.value.sessions.custom1.length && (
        <SraIcon type="custom" truthy={row.custom1 === '1'} title={store.session.value.sessions.custom1} cursor="default">
          {store.session.value.sessions.custom1[0]}
        </SraIcon>
      )}
      {!!store.session.value.sessions.custom2.length && (
        <SraIcon type="custom" truthy={row.custom2 === '1'} title={store.session.value.sessions.custom2} cursor="default">
          {store.session.value.sessions.custom2[0]}
        </SraIcon>
      )}
    </Flex>
  ) : null
}

// here we need a function to take the authlevel and the chat_mode
// authlevel can be 1, 2, 3 or 4 for admin, coord, leadt and tutor
// 4 is also a parent and then is_parent is true
// chat_mode can be:
// 1 => "Enabled for all",
// 2 => "Enabled for admins",
// 3 => "Enabled for coordinators and above",
// 4 => "Enabled for lead teachers and above",
// 5 => "Enabled for tutors and above",
// 6 => "Enabled for customers and above - exclude tutors",
// 7 => "Enabled for all - exclude tutors <=> customers]
// so we need to return true or false for each combination

export const chatEnabled = (authlevel, is_parent = 0) => {
  const chat_mode = +store.session.value.chat_mode
  if (!chat_mode) return false
  // Admins have access to all chat modes
  if (+authlevel === 1) return true

  // Coordinators have access to all chat modes except "Enabled for admins"
  if (+authlevel === 2 && ![2].includes(chat_mode)) return true

  // Lead teachers have access to all chat modes except "Enabled for admins" and "Enabled for coordinators and above"
  if (+authlevel === 3 && ![2, 3].includes(chat_mode)) return true

  // Tutors have access to all chat modes except "Enabled for admins", "Enabled for coordinators and above", "Enabled for lead teachers and above", and "Enabled for customers and above - exclude tutors"
  if (+authlevel === 4 && !+is_parent && ![2, 3, 4, 6].includes(chat_mode)) return true

  // Parents (+authlevel 4) have access to all chat modes except "Enabled for admins", "Enabled for coordinators and above", "Enabled for lead teachers and above", and "Enabled for all - exclude tutors <=> customers"
  if (+authlevel === 4 && +is_parent && ![2, 3, 4, 5, 7].includes(chat_mode)) return true

  // If none of the above conditions are met, chat is not enabled
  return false
}

export const niceDateTime = (datetime) => {
  return dayjs(datetime).fromNow()
}

var context

function startAudioContext() {
  context = new (window.AudioContext || window.webkitAudioContext)()
}

document.addEventListener('click', startAudioContext)

export const playNotificationSound = () => {
  const frequencies = [830, 1660, 2490] // frequencies for the tones
  const duration = 0.1 // duration of each tone

  if (!context) startAudioContext()

  frequencies.forEach((frequency, index) => {
    const oscillator = context.createOscillator()
    const gainNode = context.createGain()

    oscillator.connect(gainNode)
    gainNode.connect(context.destination)

    gainNode.gain.value = 0.1 // volume
    oscillator.frequency.value = frequency // frequency
    oscillator.type = 'sine' // type of sound

    const startTime = context.currentTime + duration * index
    const stopTime = startTime + duration

    oscillator.start(startTime) // play now
    oscillator.stop(stopTime) // stop playing in 0.1 seconds
  })
}

const blankStyle = {
  width: 50,
  height: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#f2f2f2',
  borderRadius: '5px'
}
const blankStyleLabel = {
  height: 50,
  display: 'inline-flex',
  // width: 'auto',
  maxWidth: 400,
  overflow: 'hidden',
  padding: 10,
  alignItems: 'left',
  justifyContent: 'left',
  backgroundColor: '#f0f0f0',
  borderRadius: '5px',
  cursor: 'pointer'
}
// thumbnail/fluid in <image preview only the non-image files have the name
export const renderFilePreview = (file, withName = false) => {
  // console.log('file', file)
  if (!file) return null

  let icon = faFile

  switch (file.type) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
      return <img className="ms-1 rounded" width={50} height={50} src={file.preview} alt={file.path} style={{ objectFit: 'cover' }} />
    case 'video/mp4':
      return (
        <video width={50} height={50} className="fit-cover rounded ms-1" style={{ objectFit: 'cover' }}>
          <source src={file.preview} type="video/mp4"></source>
        </video>
      )
    case 'application/pdf':
      icon = faFilePdf
      break
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      icon = faFileWord
      break
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      icon = faFileExcel
      break
    case 'text/csv':
      icon = faFileCsv
      break
    default:
      icon = faFile
  }
  return (
    <div style={withName ? blankStyleLabel : blankStyle}>
      <FontAwesomeIcon icon={icon} size="2x" />
      {withName && <div className="mt-1 ms-2">{file.filename}</div>}
    </div>
  )
}

export const renderVizFiles = (ulFiles, session, setUlFiles) => {
  return ulFiles.map((file) => (
    <div key={file.uid} className="ms-2" style={{ position: 'relative', display: 'inline-block', height: 50 }}>
      <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{file.path}</Tooltip>}>
        <div style={{ opacity: file.loading ? 0.3 : 1 }}>{renderFilePreview(file)}</div>
      </OverlayTrigger>
      {!file.loading ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 3,
            backgroundColor: 'gray',
            color: 'white',
            borderRadius: '50%',
            width: '15px',
            height: '15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}
          onClick={() => {
            APIService.post('apim/deleteFromS3', {
              key: `cust/${session.comptla}/chat/${file.storefilename}`
            })
            setUlFiles(ulFiles.filter((f) => f.uid !== file.uid))
          }}
        >
          <FontAwesomeIcon icon="times" />
        </div>
      ) : (
        <div
          style={{
            position: 'absolute',
            top: 17,
            right: 17,
            color: 'white',
            borderRadius: '50%',
            width: '15px',
            height: '15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}
        >
          <Spinner size="xs" animation="border" variant="primary" />
        </div>
      )}
    </div>
  ))
}

export function isNumeric(str) {
  if (typeof str === 'number') return true
  if (typeof str != 'string') return false // we only process strings!

  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ) // ...and ensure strings of whitespace fail
}

export const downloadSpreadsheet = (data, fileName = 'Sheet') => {
  const ws = XLSX.utils.json_to_sheet(data)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, fileName)
  XLSX.writeFile(wb, `${fileName}.xlsx`)
}

const extractHeaderText = (header) => {
  if (typeof header === 'function') {
    const headerElement = header()
    if (React.isValidElement(headerElement)) {
      const childrenArray = React.Children.toArray(headerElement.props.children)
      return childrenArray.join('')
    }
    return ''
  }
  return convertToPlain(header)
}

export const gridExcelExport = ({ meta, columns, data, searchProps, xtra = {} }) => {
  // **** This is ONLY for TanStack See ToolkitProvider for the original code

  const searchText = searchProps?.searchText.toLowerCase()
  const fileName = meta?.filename || 'report'

  const filteredColumns1 = columns
    .filter(({ csvExport }) => csvExport !== false)
    .map((col) => ({
      ...col,
      text: extractHeaderText(col.header) // Use the function to set the text property
    }))

  const xtraColumns = xtra?.headers?.map((h) => ({ accessorKey: h, text: h })) || []
  const filteredColumns = [...filteredColumns1, ...xtraColumns]

  const headers = filteredColumns.map(({ text }) => text)

  const xData = data.map((r) => {
    if (!xtra?.rows) return r
    const anyXtra = xtra?.rows?.find((f) => +f.id === +r.id)
    if (!anyXtra) return r

    // Create a deep copy of anyXtra to avoid modifying xtra.current
    const anyXtraCopy = { ...anyXtra }
    delete anyXtraCopy.id

    const xPart = Object.keys(anyXtraCopy).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [xtra?.headers[key] || key]: anyXtraCopy[key] }
      }),
      {}
    )
    return { ...r, ...xPart }
  })

  let rows = xData
    .filter((row, rowIndex) => {
      return searchText
        ? filteredColumns.some(({ dataField, csvFormatter, formatExtraData }) => {
            let cell = row[dataField]
            cell = csvFormatter ? csvFormatter(cell, row, rowIndex, formatExtraData) : cell
            cell = Array.isArray(cell) ? cell.join(', ') : cell
            cell = (cell || '').toLowerCase()
            return cell && cell.includes(searchText)
          })
        : true
    })
    .map((row, rowIndex) =>
      filteredColumns.reduce((acc, { text, accessorKey, csvFormatter, formatExtraData }) => {
        let value = row[accessorKey]

        value = csvFormatter ? csvFormatter(value, row, rowIndex, formatExtraData) : value
        value = Array.isArray(value) ? value.join(',') : value
        if (value === undefined) value = ''

        return { ...acc, [text]: isNumeric(value) ? +value : convertToPlain(value) }
      }, {})
    )

  const ws = XLSX.utils.json_to_sheet(rows, { header: headers })
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, fileName)
  // XLSX.writeFile(wb, `${fileName}.xlsx`)

  // const fname = `${fileName}.xlsx`

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const blob = new Blob([wbout], { type: 'application/octet-stream' })

  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = `${fileName}.xlsx`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const copyObjectToClipboard = (data) => {
  const newLine = navigator.userAgent.match(/Windows/) ? '\r\n' : '\n'
  const headers = Object.keys(data[0]).join('\t') + newLine
  const rows = data.map((r) => Object.values(r).join('\t')).join(newLine)
  copyTextToClipBoard(headers + rows)
  toast.success(`${data?.length} row${data?.length === 1 ? '' : 's'} copied to clipboard`)
}

export const formatOptionTimeLabel = ({ label, time }, { context }) => {
  if (context === 'value') {
    return (
      <div>
        {label} <small>({time})</small>
      </div>
    )
  } else {
    return (
      <div style={{ marginLeft: 10, display: 'flex', justifyContent: 'space-between' }}>
        <div>{label}</div>
        <div style={{ marginLeft: 'auto' }}>{time}</div>
      </div>
    )
  }
}

const updateTime = (option) => {
  const time = dayjs()
    .tz(option?.value?.length ? option.value : store.session.value.sys_timezone)
    .format('h:mma')
  option.time = time
  return option
}

export const updateTimezoneTimes = (timezones) => {
  return timezones.map((timezone) => {
    if (timezone.options) {
      timezone.options = timezone.options.map(updateTime)
    }
    return updateTime(timezone)
  })
}

export function parseInvoiceNumbers(invnumber) {
  // Split the string by commas
  const invoiceNumbers = invnumber.split(',')

  // Iterate through each invoice number
  return invoiceNumbers.map((number, i) => {
    // Trim any whitespace
    number = number.trim()

    // Check if the number ends with '~'
    if (number.endsWith('~')) {
      // Remove the '~' and return the number in green with '/' if it's not the last element
      number = htmlFormatterSpan(`<span style="color: #00b300;">${number.slice(0, -1)}</span>${i < invoiceNumbers.length - 1 ? ', ' : ''}`)
    } else {
      // Append '/' if it's not the last element
      if (i < invoiceNumbers.length - 1) {
        number += ', '
      }
    }

    return number
  })
}

export const isCanada = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return [
    'America/St_Johns',
    'America/Halifax',
    'America/Moncton',
    'America/Glace_Bay',
    'America/Goose_Bay',
    'America/Montreal',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Winnipeg',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Yellowknife',
    'America/Iqaluit',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Cambridge_Bay',
    'America/Rankin_Inlet'
  ].includes(timezone)
}

export const sanitizeFilename = (filename) => {
  if (typeof filename === 'undefined') {
    return ''
  }
  // Replace any character that is not a letter, number, dot, or underscore with an underscore
  return filename.replace(/[^a-zA-Z0-9._-]/g, '_')
}

export const createTextMeta = (className, headerClassName = className) => {
  return {
    headerProps: {
      className: classNames(headerClassName)
    },
    cellProps: {
      className: classNames(className),
      onClick: (e) => {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }
}

/* 

    pageInfo: {
      pageNum: pageIndex + 1,
      pageSize: pageSize
    },
    sortInfo: [
      {
        columnId: sortBy,
        sortOrder: sortDirection
      }
    ]

        tableRef.current.setRowSelection({})

classNme text-nowrap

assignments.js for rowclick
 events: {
        onClick: (e, column, row, rowIndex) => {
          setSelectedDistrict(row)
        }
      }

  const handleRowSelectionChange = useCallback(() => {
    setSelectedRecs(
      Object.keys(tableRef.current.getState().rowSelection).map((key) => {
        return filteredData[key]
      })
    )
  }, [filteredData])


#00b300 green for paid invoices

                <UncontrolledTooltip placement="auto" target="addStudentsButton">
                  Add Students
                </UncontrolledTooltip>

  const sorted = languageOptions.sort((a, b) => a.label.localeCompare(b.label))

      downloadFile(response.files[0].fname)
      // window.open(APIService.getServer() + '/' + response.files[0].fname)


className="d-inline-block" items in same row
<Card body 
maxMenuHeight={400}

      toast.error(<div dangerouslySetInnerHTML={{ __html: response.exception }} />)
                              position="fixed"
                              // menuPortalTarget={document.querySelector('.react-select')}
                              // menuPortalTarget={document.querySelector('.modal')}
                              // position="relative"
                              // position="fixed"


      <Modal 
          dialogClassName="wide-modal"
          show={show} 
          onHide={() => handleClose()}
          keyboard={false}
          backdrop="static"
          // onExit={() => console.log('Modal Exit')}
        >
        <Modal.Header closeButton>
          <Modal.Title><h5>Modal heading</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button size="sm" variant="primary" onClick={() => handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      

&zwnj;
border-0 outline-none shadow-none

    react-select in shoommodal see Payment.js
                              styles={{
                                ...oasSelectMinimal,
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              }}

                
                        styles={{
                          ...oasSelectMinimal,
                          container: (css) => ({
                            ...css,
                            width: 250,
                            textAlign: 'left',
                            height: 29
                          })
                        }}
*/

/*



  let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

      // change back to the field value object format
    const saveData = Object.entries(cat.dataJson).map(([field, value]) => {
      return {field, value}
    })

  const optionRenderer = (props) => {
    if (props.data.value === session.payroll.paycombo.selected)
      return (
        <div
          {...props.innerProps}
          className="linkSelectHover"
          style={{ backgroundColor: '#B1E0F7' }}
        >
          <b>{props.data.label}</b>
        </div>
      )
    else
      return (
        <div {...props.innerProps} className="linkSelectHover">
          {props.data.label}
        </div>
      )
  }
  <Select...
      components={{
        Option: optionRenderer
      }}



    const addDefaultSrc = (e) => {
      e.target.src = logoInvoice
    }

  const InvoiceHeader = ({ institution, logo, address1, address2 }) => (
    <Row className="align-items-center text-center mb-3">
      <Col sm={6} className="text-sm-left">
        <img
          src={`https://secure.oasesonline.com/f/images/custimages/C_${session.comptla}.jpg`}
          onError={addDefaultSrc}
          alt="logo"
          width={200}
        />
      </Col>
      <Col className="text-sm-right mt-3 mt-sm-0">
        <h2 className="mb-3">Invoice</h2>
        <h5>{institution}</h5>
        <div>{address1}</div>
        <div className="fs--1 mb-0">{address2}</div>
      </Col>
      <Col xs={12}>
        <hr />
      </Col>
    </Row>
  )


              <FloatingLabel label="Subject" className="mb-3">
                <Form.Control value={subject} onChange={({ target }) => setSubject(target.value)} />
              </FloatingLabel>

              <InputM
                name="reference2_years_known"
                value={staffMember.data.reference2_years_known}
                onChange={(e) => updateField(e.target.name, e.target.value, false)}
                onBlur={(e) => updateField(e.target.name, e.target.value)}
                label="Label"
              />


     <Tab.Container defaultActiveKey={activeTab}>
      <Nav variant="tabs" onSelect={handleActiveTab}>
        <Nav.Item>
          <Nav.Link eventKey="details" className="ps-0 cursor-pointer outline-none">
          Details
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="billing" className="px-2 px-md-3 cursor-pointer outline-none">
          Billing
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="notesdocs" className="px-2 px-md-3 cursor-pointer outline-none">
          Notes/Docs
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <Tab.Content>
        <Tab.Pane eventKey="details">
        </Tab.Pane>

        <Tab.Pane eventKey="billing">
        </Tab.Pane>

        <Tab.Pane eventKey="notesdocs">
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>

    <Flex justifyContent="between">
      <h5 className="mb-0">Followers ({69}) </h5>
      <Link to="/social/followers" className="font-sans-serif">
        All Members
      </Link>
    </Flex>

    style={{ whiteSpace: 'pre-line' }} for textarea formatting

    <meta http-equiv="cache-control" content="no-cache" />
    <meta http-equiv="expires" content="0" />
    <meta http-equiv="pragma" content="no-cache" />

    window.matchMedia("(prefers-color-scheme: dark)").matches

    toast.error(htmlFormatter(response.exception))
    import NumberFormat from "react-number-format";

    <Draggable key={`${parent}-${field.id}`}  NOT key={index}


    react-select html output:
    formatOptionLabel={(item) => {
      return <span>{htmlFormatter(item.label)}</span>
    }}

fetch('https://api.ipify.org?format=json')
  .then(response => response.json())
  .then(data => console.log(data.ip))
  .catch(error => console.error('Error:', error));

  menuPortalTarget={document.querySelector('.modal')}

  await new Promise((resolve) => setTimeout(resolve, 1000))
*/
