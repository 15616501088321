import React, { useEffect, Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import is from 'is_js'

import { ToastContainer, Zoom } from 'react-toastify'
import loadable from '@loadable/component'
import 'react-toastify/dist/ReactToastify.css'

const AuthCardLayout = loadable(() => import('./AuthCardLayout'))
const FullLayout = loadable(() => import('./FullLayout'))
const Claim = loadable(() => import('../components/people/staff/Claim'))
const TestStudent = loadable(() => import('../components/people/students/TestStudent'))
const SignUp = loadable(() => import('../components/signup/SignUp'))
const CheckInOut = loadable(() => import('../components/sessions/CheckInOut'))
const Report = loadable(() => import('../components/tools/Report'))
const ForgotPassword = loadable(() => import('../components/auth/ForgotPassword'))
const PasswordReset = loadable(() => import('../components/auth/PasswordReset'))

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows')
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome')
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox')
    }
  }, [HTMLClassList])

  return (
    <Fragment>
      <Switch>
        <Route exact path="/" component={AuthCardLayout} />
        <Route path="/login" component={AuthCardLayout} />
        <Route path="/oauth/clever/" component={AuthCardLayout} />
        <Route path="/claim/:code" component={Claim} />
        <Route path="/teststudent/:code" component={TestStudent} />
        <Route path="/signup/:code?" component={SignUp} />
        <Route path="/checkinout" component={CheckInOut} />
        <Route path="/tools/report/:id/:year?" component={Report} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/pwreset/:jwt?" component={PasswordReset} />
        <Route component={FullLayout} />

        {/* <Redirect to="/errors/404" /> */}
      </Switch>

      <ToastContainer
        style={{ width: 'auto', minWidth: 250, maxWidth: '90vw' }}
        transition={Zoom}
        closeOnClick={false}
        position="top-center"
        draggable={false}
        icon={false}
        theme="colored"
      />
    </Fragment>
  )
}

export default Layout
