// import dayjs from 'dayjs'
// console.log("dayjs().format('{YYYY} MM-DDTHH:mm:ss SSS [Z] A')", dayjs().format('YY-MM-DD HH:mm A'))
// dayjs().format('YY-MM-DD HH:mm A')

import pkg from './../package.json'

export const version = pkg.version
// import { version as Ver } from './../package.json'

// export const version = Ver
// export const version = '22.0.23'
export const navbarBreakPoint = 'xl' // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg'
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  // navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent'
}

const items = { version, navbarBreakPoint, topNavbarBreakpoint, settings }
export default items
