import axios from 'axios'
import { sendSlackMessage } from './misc'
import store from '../store'
/**
 * APIService class
 */
// const location = window.location

class APIService {
  getCookie(name) {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1)
      }
    }
    return null
  }

  constructor() {
    const sess = sessionStorage.getItem('oasmagtoken')
    const cook = this.getCookie('oasmagtoken')

    if (sess) {
      this.token = sess
      this.meth = 'session'
    } else if (cook) {
      this.token = cook
      this.meth = 'cookie'
    }

    this.setServer()
  }

  setServer(serverKey = null) {
    const servers = {
      osprey: 'https://ocean.oasesonline.com/api',
      test: 'https://test.oasesonline.com/api',
      localosprey: 'https://localhost.oasesonline.com/osprey/public/api',
      mystic: 'https://localhost.oasesonline.com/mystic'
      // panther: 'https://panther.oasesonline.com/f',
      // secure: 'https://secure.oasesonline.com/f'
    }

    if (!serverKey) {
      const useServer = window.sessionStorage.getItem('useServer')
      // const origin = location.origin
      serverKey = useServer || (origin.includes('qa.oas') || origin.includes('localhost') ? 'test' : 'osprey')

      if (origin.includes('localhost')) {
        // serverKey = 'mystic'
        serverKey = 'localosprey'
      }
      // serverKey = useServer || (origin.includes('qa.oas') || origin.includes('localhost') ? 'test' : 'osprey')
      // serverKey = useServer || (origin.includes('qa.oas') || origin.includes('panther') ? 'panther' : 'secure')
    }

    const server = servers[serverKey]

    this.server = server
    this.serverKey = serverKey
    this.baseServer = server.endsWith('/api') ? server.substring(0, server.length - 4) : server
  }

  setApiServer(server) {
    this.apiServer = server
    this.setServer()
  }

  getApiServer(server) {
    return store.session.value?.apiServer || this?.apiServer || ''
  }

  getServer() {
    return this.server
  }

  getBaseServer() {
    return this.baseServer
  }

  getServerKey() {
    return this.serverKey
  }

  getUrl(path) {
    return this.server + '/' + path + '?a=' + this.token
  }

  checkParams(params) {
    if (!params) {
      params = {
        origin: 'magenta'
      }
    } else {
      params.origin = 'magenta'
    }

    return params
  }

  failure() {
    // setMessage('An unexpected error has occurred. Please refresh the page and try again.', 'ERROR')
  }

  setToken(token) {
    sessionStorage.setItem('oasmagtoken', token)
    this.token = token
  }

  getToken() {
    return this.token
  }

  clear() {
    this.token = null
    document.cookie = 'cookieName=oasmagtoken; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    sessionStorage.removeItem('oasmagtoken')
  }

  formatSlack = (url, data, error = '') => {
    const text = store.session?.value
      ? `*Axios returned no data. TLA: ${store.session.value.comptla} User: ${store.session.value.sys_fullname} (${store.session.value.userid})*`
      : `*Axios error no session User: ${localStorage.getItem('oasmusername')}`
    return {
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text
          }
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `• url: *${url}*
 • Params: ${JSON.stringify(data, null, 2)}
 • error.response.data: ${JSON.stringify(error.response?.data, null, 2)}`
          }
        }
      ]
    }
  }

  /**
   * make a fetch post call with cors
   */
  async post(url, params, stringify = true, extraParams = null) {
    const tempparams = this.checkParams({ ...params, ...extraParams })
    const data = stringify ? { json: tempparams } : { ...tempparams }

    // Record the start time
    const startTime = Date.now()

    try {
      // if there's a trailing slash on url then remove it
      url = url.endsWith('/') ? url.substring(0, url.length - 1) : url
      const response = await fetch(url.includes('http') ? url : this.server + '/' + url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        withCredentials: true,
        credentials: 'include',
        protocol: 'h2',
        headers: {
          // 'Content-Type': ' application/x-www-form-urlencoded ',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.getToken()}`
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
      })
      const fred = await response.json()
      if (fred.code) {
        fred.success = false
        fred.exception = fred.exception || `${fred.message} <br /> ${fred.file}: ${fred.line}`
      }
      if (this.server.includes('osprey') && fred.exception) {
        // console.log('APIService response', fred)
      }
      if ((this.server.includes('osprey') || this.server.includes('ocean')) && fred?.bootosprey) {
        window.sessionStorage.removeItem('useServer')
        this.setApiServer('')
      }

      return fred
    } catch (error) {
      // Record the end time
      const endTime = Date.now()
      const elapsedTime = (endTime - startTime) / 1000 // Convert to seconds
      const timeoutMessage = elapsedTime > 30 ? ` (the request took too long to complete)` : ''

      sendSlackMessage(this.formatSlack(url, data, error))
      return { success: false, exception: error.message + timeoutMessage }
    }
  }

  async a_post(url, params, stringify = true, extraParams = null) {
    const tempparams = this.checkParams({ ...params, ...extraParams })
    const data = stringify ? { json: tempparams } : { ...tempparams }
    url = url.endsWith('/') ? url.substring(0, url.length - 1) : url

    try {
      const response = await axios({
        method: 'post',
        url: url.includes('http') ? url : this.server + '/' + url,
        data,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${this.getToken()}`
        }
      })
      return response.data
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('data', error.response.data)
        console.log('status', error.response.status)
        console.log('headers', error.response.headers)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }

      sendSlackMessage(this.formatSlack(url, params, error), true, 'magenta')
      return { success: false, exception: `Call failed at url ${url}` }
    }
  }

  async a_postdata(url, data) {
    data.append('magenta', true)
    try {
      const response = await axios({
        method: 'post',
        url: url.includes('http') ? url : this.server + '/' + url,
        data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
          Authorization: `Bearer ${this.getToken()}`
        },
        withCredentials: true
      })
      return response.data
    } catch (error) {
      console.log('Axios error:', error)
      return null
    }
  }

  async postdata(url, data) {
    data.append('origin', 'magenta')
    try {
      const response = await fetch(url.includes('http') ? url : this.server + '/' + url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        withCredentials: true,
        credentials: 'include',
        // credentials: 'same-origin',
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded',
          // Authorization: url.includes('http') ? '' : `Bearer ${sessionStorage.getItem('oasmagtoken')}`
          Authorization: `Bearer ${this.getToken()}`
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: data
      })
      return response.json()
    } catch (error) {
      console.log('Fetch error:', error)
      return null
    }
  }
}

const serv = new APIService()
export default serv
