import { useState, useEffect } from 'react'
import { BehaviorSubject } from 'rxjs'

const store = {
  session: new BehaviorSubject(null),
  exception: new BehaviorSubject(null),
  confirm: new BehaviorSubject(null),
  currentPage: new BehaviorSubject(null),
  tasks: new BehaviorSubject([]),
  messages: new BehaviorSubject([]),
  messagesTrigger: new BehaviorSubject(0),
  mask: new BehaviorSubject(false),
  trigger: new BehaviorSubject(Math.random())
}

export function setConfirm(
  cb,
  description,
  title = 'Title',
  btnType = 'danger',
  okVal = 'Ok',
  cancelVal = 'Cancel',
  okVal2 = ''
) {
  store.confirm.next({
    okVal,
    cancelVal,
    cb,
    btnType,
    description,
    title,
    okVal2
  })
}

export function clearConfirm() {
  store.confirm.next(null)
}

// Copied with gratitude from Jose: https://github.com/josebalius/use-behavior-subject
export function useBehaviorSubject(subject) {
  const [value, setValue] = useState(subject.getValue())
  const updateValue = (v) => {
    subject.next(v)
  }
  useEffect(() => {
    const subscription = subject.subscribe((v) => {
      setValue(v)
    })
    return () => subscription.unsubscribe()
    // Jose's code didn't have subject in the dep array
  }, [subject])
  return [value, updateValue]
}

export default store
