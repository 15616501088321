import React from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import Lottie from 'lottie-react'

import animationData from '../../assets/json/circle-loading.json'
// import animationData from 'assets/json/hand-loading.json'

const Loader = ({
  py = 'py-5',
  animation = 'grow',
  variant = 'primary',
  loading = null,
  lottie = false,
  type = 'grow',
  size = 'lg',
  color = 'primary',
  ...rest
}) => {
  const vari = (loading !== true && loading) || variant

  const Lot = () => {
    return (
      <div>
        <Lottie style={{ height: 150, width: 150 }} animationData={animationData} />
      </div>
    )
  }

  return (
    <Row data-test="cy.loader" className={`flex-center ${py}`}>
      <Col xs="auto">{lottie ? <Lot /> : <Spinner animation={animation} variant={vari} {...rest} />}</Col>
    </Row>
  )
}

export default Loader
