import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import Main from './Main'
import './helpers/initFA'
import UseErrorBoundary from './components/common/ErrorBoundary'

const container = document.getElementById('oas-main')
const root = createRoot(container)

root.render(
  <UseErrorBoundary>
    <Main>
      <App />
    </Main>
  </UseErrorBoundary>
)
