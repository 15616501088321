import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const SoftBadge = forwardRef(({ bg = 'primary', pill, children, className, style }, ref) => {
  return (
    <div
      ref={ref}
      className={classNames(className, `badge badge-subtle-${bg}`, {
        'rounded-pill': pill
      })}
      style={style}
    >
      {children}
    </div>
  )
})

SoftBadge.propTypes = {
  bg: PropTypes.oneOf(['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']),
  pill: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
}

export default SoftBadge
